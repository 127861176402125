// About.js
import React from "react";
import './about.css'
import { AboutData } from "./about.data";
import image from './../../images/Mask group (9).png'
import { useMediaQuery } from "react-responsive";
import { Helmet } from "react-helmet";
import keywordsData from './../keywords.json'
const About = ({language}) => {
    
    const screenResolution = useMediaQuery({ query: '(max-width: 1500px)' })
    const screenResolutionForteen = useMediaQuery({ query: '(max-width: 1400px)' })
    const screenResolutionThird = useMediaQuery({ query: '(max-width: 1380px)' })
    const screenResolutionFourth = useMediaQuery({ query: '(max-width: 1200px)' })
    const keywords = keywordsData.keywords;
    const filteredData = AboutData.filter(item => item.lang === language);  
    return (
        <div className={`flex flex-col gap-3 ${screenResolution ? (screenResolutionForteen ? 'px-6 py-8' : 'px-20 py-10') : 'px-24 py-16'} about`}>
             <Helmet>
                <title>Printwall - {language === 'GEO'?'კომპანიის შესახებ' :language === 'ENG'?'About us':'О компании' }</title>
                <meta name="description" content="Learn about Printwall, a leading provider of automated drawing solutions for transforming walls with innovative art. Discover our commitment to quality, creativity, and affordability, and how we're revolutionizing the world of wall decoration." />
                <meta name="description" content="შეიტყვეთ Printwall-ის შესახებ, ავტომატური ნახაზის გადაწყვეტილებების წამყვანი პროვაიდერი კედლების ინოვაციური ხელოვნებით გარდაქმნისთვის. აღმოაჩინეთ ჩვენი ვალდებულება ხარისხის, კრეატიულობისა და ხელმისაწვდომობისადმი და როგორ ვცვლით კედლის დეკორაციის სამყაროს." />
                <meta name="description" content="Узнайте о Printwall, ведущем поставщике решений для автоматизированного рисования, позволяющих преобразовывать стены с помощью инновационных произведений искусства. Узнайте о нашей приверженности качеству, креативности и доступности, а также о том, как мы совершаем революцию в мире отделки стен." />
                {keywords.map(keyword => (
                    <meta name="keywords" content={keyword} key={keyword} />
                ))}
            </Helmet>
            {filteredData.map((aboutItems,index) => {
                        return (
                            <h2 key={index}>{aboutItems.header }</h2>
                        )
                    })}
            <div className={`flex ${screenResolutionFourth ? 'flex-col gap-4' : 'flex-row '} gap-8 `}>
                <div className="flex flex-col gap-14">

                    {filteredData.map((aboutItems,index) => {
                        return (
                            <div className="flex flex-col" key={index }> 
                                <h3>{aboutItems.subheader }</h3>
                                <p style={screenResolutionThird ? {} : {whiteSpace:'pre-line',textAlign:'justify'}} >{aboutItems.text}</p>
                                {aboutItems.textArray && Array.isArray(aboutItems.textArray) && 
                                    aboutItems.textArray.map((subtextItem, index) => (
                                        <div key={index}>
                                            <h4 key='header four' style={{textAlign:'justify'}}>
                                                {subtextItem.subtext}<span style={screenResolutionFourth ? {} :{whiteSpace:'pre-line',textAlign:'justify'}}>{subtextItem.maintext}</span>
                                            </h4>
                                        </div>
                                    ))
                                }
                            </div>
                        )
                    })}
                </div>
                {screenResolutionFourth ? <></> : <img src={image } alt="imageForPage" key='image'/>}
                
            </div>
        </div>
    );
};

export default About;
