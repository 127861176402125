import Facebook from './../../images/fb-01.png';
import Instagram from './../../images/instagram-01.png';
import Youtube from './../../images/youtube-01.png';
import Tiktok from './../../images/tiktok-01.png';

export const SocialData = [
    {
        image: `${Facebook}`,
        name: 'Facebook',
        link: `https://www.facebook.com/PrintWallGeorgia`,
    },
    {
        image: `${Instagram}`,
        name: 'Instagram',
        link: `https://www.instagram.com/sakartvelodan_/`,

    },
    {
        image: `${Youtube}`,
        name: 'Youtube',
        link: `https://www.youtube.com/channel/UCNOVw2tU4MfJoY7aXrEkwMQ`,

    },
    {
        image: `${Tiktok}`,
        name: 'Tiktok',
        link: `https://www.tiktok.com/@printwall.ge`,

    },

]