import React from "react";
import './home.css'
import {WhyWeData} from './why.we.data'
import { useMediaQuery } from "react-responsive";


const WhyWe = ({language}) => {
    const isBigScreen = useMediaQuery({ query: '(min-width: 1450px)' })
    const smallScreenSeven = useMediaQuery({query: '(max-width: 700px)'});
    const filteredData = WhyWeData.filter(item => item.lang === language);  

    return (
        <div className={`flex flex-col ${smallScreenSeven ? 'px-10' : 'px-24'} py-16 gap-y-14 why-font`}>
            <h3 className="text-2xl font-bold text-center text-black">{language==='GEO'? 'კომპანიის უპირატესობები' : language === 'eng' ? 'Advantages of the company':'Преимущества компании'}</h3>
            <div className={`${isBigScreen ? "grid grid-cols-2 gap-x-5 gap-y-5" : 'flex flex-col gap-y-5'}`}>
                {filteredData.map(data => {
                    return (
                        <div className={`flex ${smallScreenSeven ? 'flex-col gap-4 text-center py-2' : 'flex-row gap-6 py-2 pl-12 pr-12'} items-center  why-we-data `} key={data.text}>
                            <img src={data.image} alt="" />
                            <p style={isBigScreen ? { whiteSpace: 'pre-line' } : {}} className="text-white">{data.text}</p>
                        </div>
                    )
                })}
            </div>

        </div>
    )
}

export default WhyWe;