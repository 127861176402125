// Home.js
import React from "react";
import './prices.css';
import { PricesData } from './prices.data'
import { useMediaQuery } from "react-responsive";
import { Helmet } from "react-helmet";
import keywordsData from './../keywords.json'
const Prices = ({language}) => {
    const bigScreen = useMediaQuery({query : '(max-width: 1360px)'})
    const smallScreen = useMediaQuery({ query: '(max-width:1000px)' })
    const verySmallScreen = useMediaQuery({ query: '(max-width:640px)' })
    const keywords = keywordsData.keywords;
    return (
        <div className={`flex flex-col gap-3 py-16 price-container ${bigScreen ? (smallScreen ? 'px-6 py-8' : 'px-24') : 'pr-96 pl-24'} gap-y-14 w-full`} >
             <Helmet>
                <title>Printwall - {language === 'GEO'?'ფასები' :language === 'ENG'?'Pricelist':'Цены' }</title>
                <meta name="description" content="Discover Printwall's competitive prices for transforming your walls with our automated drawing solutions. Explore our cost-effective pricing options and bring creativity to your space without breaking the bank." />
                <meta name="description" content="აღმოაჩინეთ Printwall-ის კონკურენტუნარიანი ფასები თქვენი კედლების გარდაქმნისთვის ჩვენი ავტომატური ნახაზის გადაწყვეტილებებით. გამოიკვლიეთ ჩვენი ეკონომიური ფასების ვარიანტები და შეიტანეთ კრეატიულობა თქვენს სივრცეში ბანკის დარღვევის გარეშე." />
                <meta name="description" content="Откройте для себя конкурентоспособные цены Printwall на преобразование ваших стен с помощью наших автоматизированных решений для рисования. Изучите наши экономически эффективные варианты цен и привнесите творческий подход в свое пространство, не тратя денег." />
                {keywords.map(keyword => (
                    <meta name="keywords" content={keyword} key={keyword} />
                ))}
            </Helmet>
            <h2 className="text-black">{language === 'GEO' ? 'ფასები' : language === 'ENG' ? 'Prices' : 'Стоимость'}</h2>
            <div className="mapping-text ">

            {
                PricesData.map((text, index) => {
                    
                    if (text.texts && Array.isArray(text.texts)) {
                        return (
                            <div key={index} >
                                {text.texts
                                        .filter(item => item.lang === language)
                                        .map((text, index) => (
                                        <p key={index} className="text-black" dangerouslySetInnerHTML={{
                                        __html: text.text
                                            .replace(/facebook/gi, '<a rel="canonical" href="https://www.facebook.com/PrintWallGeorgia">Facebook</a>')
                                            .replace(/instagram/gi, '<a rel="canonical" href="https://www.instagram.com/sakartvelodan_/">Instagram</a>')
                                        }} />
                                ))}
                            </div>
                        )
                    } else {
                        <></>
                    }
                        
                    
                })
            }
            </div>
                {PricesData.map((prices, index) => {
                if (prices.textArray && Array.isArray(prices.textArray)) {
                    return (
                        <div key={index} className="-mt-20">
                            <div className={` ${bigScreen ? 'flex flex-col gap-4 w-full items-center' : 'grid grid-cols-2 gap-5 mt-4'}`}>
                                {
                                    prices.textArray
                                        .filter(items => items.lang === language)
                                        .map((item, idx) => (
                                    <div className={`flex ${bigScreen ? (smallScreen ? (verySmallScreen ? 'w-full px-3 py-8' :'w-4/5 py-10') :'w-4/6') : 'w-full'} flex-row w items-center justify-between  px-10 py-12 prices bg-primary`} key={index}>
                                       
                                        <p className="text-2xl text-white" >
                                            {item.m2}
                                            {language === 'GEO' ? 'მ' : language === 'RUS' && idx === prices.textArray.length - 1 ? 'от-М' : language === 'GEO' ? 'მ' : language === 'ENG' ? 'm' : 'М'}<sup>2</sup> {language === 'GEO' && idx === prices.textArray.length - 1 ? '-დან' : language === 'ENG' && idx === prices.textArray.length - 1 ? 'and more' : ''}
                                        </p>

                                        <h3 className="text-4xl text-white">
                                            {item.prices} {language === 'GEO' ? 'ლარი' : language === 'ENG' ? 'Lari' : 'Лари'}
                                        </h3>
                                    </div>
                                ))}
                            </div>
                        </div>
                    );
                } else {
                    return (
                        <div key={index}>
                        </div>
                    );
                }
            })}
        </div>
    );
};

export default Prices;