// Home.js
import React from "react";
import Form from './../home/email/form'
import { useMediaQuery } from "react-responsive";
import { Helmet } from "react-helmet";
import keywordsData from './../keywords.json'
const Contacts = ({ language}) => {
    const smallScreen = useMediaQuery({ query: '(max-width: 1000px)' })
    const keywords = keywordsData.keywords;
    return (
        <div>
             <Helmet>
                <title>Printwall - {language === 'GEO'?'კოტაქტი' :language === 'ENG'?'Contact':'Контакт' }</title>
                <meta name="description" content="Contact Printwall to inquire about our automated drawing solutions, pricing, customization options, and more. Reach out to our dedicated team for expert assistance in transforming your walls with innovative wall art." />
                <meta name="description" content="Свяжитесь с Printwall, чтобы узнать о наших решениях для автоматического рисования, ценах, возможностях настройки и многом другом. Обратитесь к нашей преданной команде за квалифицированной помощью в преобразовании ваших стен с помощью инновационного настенного искусства." />
                <meta name="description" content="დაუკავშირდით Printwall-ს, რათა იკითხოთ ჩვენი ავტომატური ნახაზის გადაწყვეტილებების, ფასების, პერსონალიზაციის ვარიანტებისა და სხვა. დაუკავშირდით ჩვენს თავდადებულ გუნდს ექსპერტთა დახმარებისთვის თქვენი კედლების ინოვაციური კედლის ხელოვნებით გარდაქმნაში." />
                {keywords.map(keyword => (
                    <meta name="keywords" content={keyword} key={keyword} />
                ))}
            </Helmet>
            <Form language={ language} />
            <iframe src="https://www.google.com/maps/embed?pb=!1m18!1m12!1m3!1d744.618504337833!2d44.774428792659045!3d41.71028919817031!2m3!1f0!2f0!3f0!3m2!1i1024!2i768!4f13.1!3m3!1m2!1s0x40440cd2ca43d37b%3A0xf2867e4cd04c625d!2s18-20%20Ateni%20St%2C%20T&#39;bilisi!5e0!3m2!1sen!2sge!4v1711447838823!5m2!1sen!2sge"
                title="Google Maps Location"
                style={{ border: '0' }}
                height={smallScreen? '480px' : '600px'}
                allowFullScreen={ true}
                loading="lazy"
                referrerPolicy="no-referrer-when-downgrade"
                className={`w-full `}>
                
                </iframe>
        </div>
    );
};

export default Contacts;