import img1 from './../../images/Figura_13.png'
import img2 from './../../images/Figura_10.png'
import img3 from './../../images/Figura_12.png'
import img4 from './../../images/Figura_14.png'
import img5 from './../../images/10year.png'
import img6 from './../../images/Figura_9.png'

export const WhyWeData = [
    {
        image: `${img1}`,
        lang:'GEO',
        text: `ხელმისაწვდომი ფასი`
    },
    {
        image: `${img1}`,
        lang:'ENG',
        text: `Affordable price`
    },
    {
        image: `${img1}`,
        lang:'RUS',
        text: `Доступная цена`
    },
    {
        image: `${img2}`,
        lang:'GEO',
        text: `დიზაინერის მომსახურება: ვიზუალის დამუშავება და მომზადება`
    },
    {
        image: `${img2}`,
        lang:'ENG',
        text: `Designer services: visual processing and preparation`
    },
    {
        image: `${img2}`,
        lang:'RUS',
        text: `Услуги дизайнера: визуальная обработка и подготовка`
    },
    {
        image: `${img3}`,
        lang:'GEO',
        text: `სწრაფი მომსახურეობა ბეჭდვის სიჩქარით 5-15მ2 საათში`
    },
    {
        image: `${img3}`,
        lang:'ENG',
        text: `Fast service with a printing speed of 5-15m2 per hour`
    },
    {
        image: `${img3}`,
        lang:'RUS',
        text: `Быстрое обслуживание со скоростью печати 5-15м2 в час.`
    },
    {
        image: `${img4}`,
        lang:'GEO',
        text:  `ეკოლოგიურად სუფთა საღებავები რომელთაც არ აქვთ სუნი და არ შეიწოვებიან ზედაპირზე`
    },
    {
        image: `${img4}`,
        lang:'ENG',
        text:  `Environmentally paints that do not have a smell and are not absorbed by the surface`
    },
    {
        image: `${img4}`,
        lang:'RUS',
        text:  `Экологически чистые краски, не имеющие запаха и не впитывающиеся в поверхность.`
    },
    {
        image: `${img5}`,
        lang:'GEO',
        text: `10 წლიანი გარანტია შესრულების ხარისხზე`
    },
    {
        image: `${img5}`,
        lang:'ENG',
        text: `10-year warranty on performance quality`
    },
    {
        image: `${img5}`,
        lang:'RUS',
        text: `10-летняя гарантия на качество исполнения`
    },
    {
        image: `${img6}`,
        lang:'GEO',
        text: `გამოსახულების ბეჭდვა 2880პ გარჩევადობით`
    },
    {
        image: `${img6}`,
        lang:'ENG',
        text: `Image printing with 2880p resolution`
    },
    {
        image: `${img6}`,
        lang:'RUS',
        text: `Печать изображений с разрешением 2880p.`
    },
]