import React from "react";
import './footer.css'
import { SocialData } from "./social.footer.data";

const SocialFooter = () => {
    return (
        <div className="flex items-end justify-end gap-2 social-footer">
            {SocialData.map((items) => {
                return (
                    <div className="w-6 h-fit" key={items.name} >
                        <a rel='canonical noreferrer' href={items.link} target='_blank' >
                            <img src={items.image} alt={ items.name}/>
                        </a>
                    </div>
                )
            })}
        </div>
    )

}

export default SocialFooter;