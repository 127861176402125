import React, {useState, useEffect} from "react";
import { SliderData } from "./slider.data";
import "./home.css"
import { NavLink } from "react-router-dom";
import { useMediaQuery } from "react-responsive";


const Header = ({language }) => {
    
    const [currentSlider, setCurrentSlider] = useState(0);
    const slider = SliderData.length;
    
    useEffect(() => {
        const nextSlide = () => {
            setCurrentSlider((prevIndex) =>
            prevIndex === 0 ? slider - 1 : prevIndex - 1
            );
        };
        
        const slideInterval = setInterval(nextSlide, 5000); 
        
        return () => clearInterval(slideInterval);
    }, [slider]);
    
    // const isBigScreen = useMediaQuery({ query: '(min-width: 1450px)' })
    const isMaxMediumScreen = useMediaQuery({ query: '(min-width: 1250px)' })
    const tooSmallScreen = useMediaQuery({ query: '(max-width:760px)' })
    const verySmallScreen = useMediaQuery({ query: '(max-width:610px)' })
    const smallScreenOne = useMediaQuery({ query: '(max-width:532px)' })
    const mobileScreenOne = useMediaQuery({ query: '(max-width:532px)' })
    const filteredData = SliderData.filter(item => item.lang === language);  

    return (
        <div className="relative">
        
            
            {SliderData.map((slide, index) => {
                return (
                    <div className={index === currentSlider ? "slide current ": "slide "} key={index}>
                        {index === currentSlider && (
                            <>
                                <img src={slide.image} alt="slide" />
                                
                                <div className={`flex flex-col info ${tooSmallScreen ? 'gap-y-3' : 'gap-y-5'} slider-font`}>
                                    {slide.texts.filter(langText => langText.lang === language).map((texts, index) => {
                                        return (
                                            <>
                                                <h2 style={{ whiteSpace: 'pre-line' }} className={`text-white ${!isMaxMediumScreen ? (tooSmallScreen ? (verySmallScreen ? (smallScreenOne? 'text-2xl' : 'text-4xl') : 'text-5xl') :'text-6xl'):'text-8xl '}`} >{texts.ht }</h2>
                                                {!mobileScreenOne?
                                                <p className={`text-white ${!isMaxMediumScreen ? (tooSmallScreen ? (verySmallScreen ? (smallScreenOne ? 'text-lg' : 'text-2xl') : 'text-3xl') : 'text-4xl') : 'text-4xl '}`} style={!isMaxMediumScreen ? { whiteSpace: 'pre-line' } : {}}>{texts.text}</p>
                                                :<></>
                                                }
                                                <NavLink rel='canonical' to="/PrintWall" className="text-2xl text-white cursor-pointer link w-fit"><p className={`${!isMaxMediumScreen ? (smallScreenOne ? 'px-4 py-2 text-base' : 'px-6 py-4 text-lg') : 'px-8 py-6 text-2xl'}`}>{texts.link}</p></NavLink>
                                            </>
                                        )
                                    })}
                                    
                                </div>
                            </>
                            
                        )}
                    </div>
                )
            })}
        </div>

    );
};

export default Header;