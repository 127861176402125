// Home.js
import React from "react";
import Header from "./header";
import SmallInfo from "./smallinfo";
import WhyWe from "./whywe";
import Offer from "./offer";
import VideoShow from "./video";
import Form from "./email/form";
import { Helmet } from "react-helmet";
import keywordsData from './../keywords.json'
const Home = ({language}) => {
    const keywords = keywordsData.keywords;
    return (
        <div className="flex flex-col">
            <Helmet>
                <title>Printwall - {language === 'GEO'?'დაპრინტე ჩვენთან ერთად კედელზე' :language === 'ENG'?'Print with us on the wall':'Печатайте вместе с нами на стене' }</title>
                <meta name="description" content="Revamp your walls inexpensively with Printwall's automated drawing solutions—quick, budget-friendly alternatives to hand-drawn designs!" />
                <meta name="description" content="განაახლეთ თქვენი კედლები იაფად Printwall-ის ავტომატური სახატავი გადაწყვეტილებებით — ხელით დახატული დიზაინის სწრაფი, საბიუჯეტო ალტერნატივები!" />
                <meta name="description" content="Обновите свои стены недорого с помощью решений для автоматического рисования Printwall — быстрой и экономичной альтернативы рисованному дизайну!" />
                {keywords.map(keyword => (
                    <meta name="keywords" content={keyword} key={keyword} />
                ))}
            </Helmet>
            <div className='navInHome'>

            </div>

            <Header language={ language} />
            <SmallInfo language={ language}/>
            <WhyWe language={ language}/>
            <Offer language={ language}/>
            <VideoShow />
            <div className="h-1 info-content"></div>
            <Form language={ language} />
        </div>
    );
};

export default Home;