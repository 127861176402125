import React from 'react';
import EmailIcon from './../../images/mail.png'

const EmailButton = (prop) => {
  const handleEmailClick = () => {
    window.location.href = 'mailto:info@printwall.ge';
  };

  return (
    <button onClick={handleEmailClick} className='flex flex-row gap-x-3 w-fit items-center'>
      <img src={EmailIcon} alt="Email Icon" className='w-4 h-fit '/>
      <p className='text-white text-m   '>{ prop.email }</p>
    </button>
  );
};

export default EmailButton;
