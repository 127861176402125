// import image from './../../images/MaskGroup.png';
import outside from './../../images/outside.jpg';
import mirror from './../../images/officeMirror.jpg';
import car from './../../images/car1.jpg';
import wall from './../../images/wall.jpg';

export const ServiceData = [
    {
        header: 'კედლებზე ხატვა',
        lang:'GEO',
        text: `ციფრული 3D ბეჭდვა  - ნებისმიერი სივრცის / შენობის ინტერიერი დამოკიდებულია ბევრ დეტალზე, მათ შორის კედლების გაფორმებაზე. ნახატებისა და ფოტოების კედელზე გადასატანად ყველაზე ეფექტურ და თანამედროვე მეთოდად  მიიჩნევა 3D ბეჭდვა. ეს ახალი ტექნოლოგია აფართოებს კედლების გაფორმების საზღვრებს და მაქსიმალურად მიმზიდველს და ხარისხიანს ხდის.  ნახატების გარდა, კედელზე შესაძლებელია ნებისმიერი ინფორმაციის, ლოგოტიპისა თუ კომპანიის დასახელების გადატანა - დაბეჭდვა. 

        კედლებზე 3D ბეჭდვის სერვისის შეკვეთა შესაძლებელია ჩვენს ვებგვერდზე, ასევე კომპანიის Facebook და Instagram გვერდების საშუალებით. 
        კედელზე ფართო / მასშტაბიანი ბეჭდვა არის ეფექტური მეთოდი შექმნათ უნიკალური ინტერიერი ნებისმიერი სივრცისთვის.

        სტიკერებთან და ფოტო-შპალერთან მუშაობისგან განსხვავებით, პრინტერის გამოყენებით გამოსახულების კედელზე გადატანით თავიდან აირიდებთ დამატებითი რესურსების გამოყენებას. პრინტერის სამუშაო პროცესი კონტროლდება სპეციალური პროგრამით, რომელიც ახდენს ბეჭდვის პარამეტრების კონფიგურაციას. პრინტერიარ მოძრაობს ჰორიზონტალურად (მარცხნივ/მარჯვნივ), არ უშვებს შეცდომებს ბეჭდვის დროს. კედელზე UV ბეჭდვა საშუალებას გაძლევთ გამოსახულება მიიღოთ ფოტოგრაფიული სიზუსტით.
        
        3D ბეჭდვის პროცესი მიმდინარეობს მაღალი სიჩქარით. ზოგჯერ პროდუქტიულობა საათში 5 მ2-ს აღწევს, რაც იძლევა საშუალებას მოკლე დროში დასრულდეს კედლის მოხატვა. მაგალითად, ამ ტექნოლოგიური პროცესის გამოყენებით მრავალი სივრცის  დეკორაცია შეიძლება მზად იყოს ერთ დღეში.ეს ძალიან მნიშვნელოვანია ისეთი სივრცეებისთვის, როგორიცაა საბავშვო ბაღები, კაფეები, სილამაზის სალონები, ოფისები და ა.შ., რადგან პროცესი არ საჭიროებს ხანგრძლივი დროით ობიექტის შეჩერებას და მოგების დაკარგვას.
         
        ამ გზით გამოსახულების ბეჭდვა ნებისმიერ ზედაპირზეა შესაძლებელი. ეს შეიძლება იყოს მინის, პლასტმასის ან ბეტონის. ამრიგად, პროექტის დიზაინი მხოლოდ ფანტაზიაზეა დამოკიდებული, ჩვენი თანამედროვე დანადგარი შეძლებს ნებისმიერი სურათის, იდეის კედელზე გადატანას.
        `,
        image: `${wall}`
    },
    {
        header: 'Роспись на стенах',
        lang:'RUS',
        text: `Цифровая 3D-печать – интерьер любого помещения/здания зависит от многих деталей, в том числе и от отделки стен. 3D-печать считается самым эффективным и современным методом переноса картин и фотографий на стену. Эта новая технология расширяет границы отделки стен и делает ее максимально привлекательной и качественной. Помимо фотографий, на стене можно напечатать любую информацию, логотип или название компании.
        Заказать услугу 3D-печати стен можно на нашем сайте, а также на страницах компании в Facebook и Instagram.
        Печать на стенах – эффективный способ создать неповторимый интерьер любого помещения.
        В отличие от наклеек, воспользовавшись принтером и перенеся изображение на стену, вы избегаете использования дополнительных ресурсов. Рабочий процесс принтера контролируется специальной программой, настраивающей параметры печати. Принтер не двигается по горизонтали (влево/вправо), не допускает ошибок при печати. УФ печать на стене позволяет получить изображение с фотографической точностью.
        Процесс 3D-печати происходит на высокой скорости. Иногда производительность достигает 5 м2 в час, что позволяет закончить покраску стены в короткие сроки. Например, с помощью этого технологического процесса отделка многих помещений может быть готова за один день. Это очень важно для таких помещений, как детские сады, кафе, салоны красоты, офисы и т. д., поскольку процесс не требует длительного времени для остановки работы объекта и потери прибыли.
        Таким образом изображение можно напечатать на любой поверхности. Это может быть стекло, пластик или бетон. Поэтому дизайн проекта зависит только от фантазии. Наше современное оборудование сможет перенести на стену любой образ и идею.
     `,
        image: `${wall}`
    },
    {
        header: 'Painting on the walls',
        lang:'ENG',
        text: `Digital 3D printing – the interior of any room/building depends on many details, including the decoration of the walls. 3D printing is considered the most effective and modern method of transferring paintings and photographs to the wall. This new technology expands the boundaries of wall decoration and makes it as attractive and high-quality as possible. In addition to photographs, you can print any information, logo or company name on the wall.
        You can order the 3D wall printing service on our website, as well as on the company’s Facebook and Instagram pages.
        Wall printing is an effective way to create a unique interior of any room.
        Unlike stickers, by using a printer and transferring the image to the wall, you avoid using additional resources. The printer's workflow is controlled by a special program that configures printing parameters. The printer does not move horizontally (left/right) and does not make errors when printing. UV printing on the wall allows you to obtain an image with photographic precision.
        The 3D printing process occurs at high speed. Sometimes the productivity reaches 5 m2 per hour, which allows you to finish painting the wall in a short time. For example, with the help of this technological process, the finishing of many rooms can be completed in one day. This is very important for premises such as kindergartens, cafes, beauty salons, offices, etc., since the process does not require a long time to stop the operation of the facility and loss of profit.
        Thus, the image can be printed on any surface. It could be glass, plastic or concrete. Therefore, the design of the project depends only on imagination. Our modern equipment can transfer any image and idea to the wall.
    `,
        image: `${wall}`
    },
    {
        header: 'გარე ფასადებზე ხატვა',
        lang:'GEO',
        text: `PrintWall გთავაზობთ  გარე სივრცეების ვიზუალურად მიმზიდველად გადაქცევის ინოვაციურ გადაწყვეტას. ბეჭდვის უახლესი ტექნოლოგიით ჩვენ ვაცოცხლებთ ფანტაზიას გარე კედლებზე, ფასადებსა და სტრუქტურებზე, ვაქცევთ ჩვეულებრივ ზედაპირებს არაჩვეულებრივ ხელოვნების ნიმუშებად. 
        ჩვენი კომპანია გთავაზობთ მომსახურების ფართო სპექტრს, რომელიც მორგებულია სპეციალურად გარე ბეჭდვის შემთხვევებისთვის.

        კედლის  3D გამოსახულებები: ჩვენ ვქმნით კედლის უნიკალურ 3D გამოსახულებებს , რომლებიც შენობის გარე კედლებს გარდაქმნის უფრო მიმზიდველად. იქნება ეს შენობის ფასადი, სასაზღვრო კედელი თუ საჯარო სივრცე, ჩვენი ნამუშევრები ნებისმიერ გარე სივრცეს ანიჭებს ინდივიდუალურობას და ესთეტიკურობას.
        
        ბრენდინგი და რეკლამა: ბიზნესებს გარე ბეჭდვის სერვისით შეუძლიათ გაზარდონ თავიანთი ბრენდის ხილვადობა და შექმნან ეფექტური სარეკლამო კამპანიები. ჩვენი პრინტები მაღალი ხარისხის და ატმოსფერული მოვლენების მიმართ მდგრადია, რაც მათ იდეალურს ხდის გარე ნიშნების და სარეკლამო გამოსახულებების დასამზადებლად.

        ბეჭდვა საჯარო სივრცეში: ჩვენ შეგვიძლია შევქმნათ გამოსახულებები საჯარო სივრცეებში, რომლებიც გაამდიდრებენ ურბანულ პეიზაჟებს და ხელს შეუწყობენ საზოგადოებისთვის საინტერესო და მრავალფეროვანი გარემოს შექმნას და საჯარო სივრცეების კულტურულად აქტიურ კერებად ქცევას.
        ღონისძიებების გაფორმება: გარე ბეჭდვის სერვისით ასევე შესაძლებელია ღია სივრცეებში ღონისძიებებისა და ივენთებისგამრავალფეროვნება. იქნება ეს ფესტივალი, კონცერტი თუ კორპორატიული ღონისძიება, პერსონალურად დაბეჭდილი ბანერები და ფონები ნებისმიერ ადგილს მიანიჭებენ განსხვავებულ ელფერსა და დახვეწილობას.

        კედელზე UV 3D ბეჭდვის უპირატესობები:
        მდგრადობა: ჩვენი პრინტები მდგრადია გაფერმკრთალების, ჩამორეცვისა  და აქერცვლის მიმართ, რაც უზრუნველჰყოფს გამოსახულების ხარისხის ხანგრძლივად შენარჩუნებას.

        მრავალფეროვნება: ბეჭდვის მოწინავე ტექნოლოგიის გამოყენებით შესაძლებელია დავბეჭდოთ ზედაპირების ფართო სპექტრზე, მათ შორის ბეტონის, მინის, ლითონისა და ხის ჩათვლით, რაც გ კრეატიული გამოხატვის უსაზღვრო შესაძლებლობებს.

        ინდივიდუალურობა: ყველა პროექტი შემუშავებულია ჩვენი კლიენტების უნიკალური ხედვისა და მოთხოვნების დასაკმაყოფილებლად, რაც უზრუნველყოფს ზუსტად იმ შედეგს, რომელიც ასახავს მათ ბრენდის ან პიროვნულ იდენტობას.

        უსაფრთხოება: პრიორიტეტს ვანიჭებთ ეკოლოგიურ ასპექტს და ვიყენებთ არატოქსიკურ საღებავებს, რომლებიც უსაფრთხოა გარემოსთვის და შეესაბამება სტანდარტებს.
        `,
        image: `${outside}`
    },
    {
        header: 'Покраска наружных фасадов',
        lang:'RUS',
        text: `PrintWall предлагает инновационное решение для визуальной привлекательности открытых пространств. Благодаря новейшим технологиям печати мы воплощаем в жизнь фантазию на наружных стенах, фасадах и конструкциях. Мы превращаем обычные поверхности в необыкновенные произведения искусства.
        Наша компания предлагает широкий спектр услуг, специально разработанных для наружной печати.
           3D-рендеринг стен: мы создаем уникальные 3D-рендеринги стен, которые превращают внешние стены здания в нечто более привлекательное. Будь то фасад здания, ограждающая стена или общественное пространство. Наши работы придают индивидуальность и эстетику любому уличному пространству.
           Брендинг и реклама. Компании, предоставляющие услуги наружной печати, могут повысить узнаваемость своего бренда и создать эффективные рекламные кампании. Наши отпечатки отличаются высоким качеством и устойчивостью к атмосферным воздействиям, что делает их идеальными для наружной вывески и рекламы.
        Печать в общественных местах: мы можем создавать изображения в общественных местах, которые обогащают городские пейзажи и способствуют созданию интересной и разнообразной среды для общественности, а также делают общественные места культурно активными центрами.
           Оформление мероприятий: с услугой наружной печати также можно разнообразить мероприятия и мероприятия на открытых пространствах. Будь то фестиваль, концерт или корпоративное мероприятие, напечатанные на заказ баннеры и фоны добавят изюминку и изысканность любому месту.
          Преимущества UV 3D печати на стене:
           Долговечность: наши отпечатки устойчивы к выцветанию, стирке и отслаиванию, что обеспечивает длительное качество изображения.
           Универсальность: используя передовые технологии печати, можно печатать на самых разных поверхностях, включая бетон, стекло, металл и дерево, что открывает безграничные возможности для творческого самовыражения.
           Индивидуальность: каждый проект разрабатывается с учетом уникального видения и требований наших клиентов, обеспечивая точный результат, отражающий их бренд или индивидуальность.
           Безопасность: Мы уделяем приоритетное внимание экологическому аспекту и используем нетоксичные краски, безопасные для окружающей среды и соответствующие стандартам.
        `,
        image: `${outside}`
    },
    {
        header: 'Painting of external facades',
        lang:'ENG',
        text: `Printwall offers an innovative solution for the visual appeal of outdoor spaces. Thanks to the latest printing technologies, we bring your imagination to life on external walls, facades and structures. We transform ordinary surfaces into extraordinary works of art.
        Our company offers a wide range of services specifically designed for outdoor printing.
        3D Wall Renderings: We create unique 3D wall renderings that transform the exterior walls of a building into something more attractive. Be it a building façade, a boundary wall or a public space. Our work adds personality and aesthetics to any outdoor space.
        Branding and advertising. Companies that provide outdoor printing services can increase their brand awareness and create effective advertising campaigns. Our prints are high quality and weather resistant, making them ideal for outdoor signage and advertising.
    Printing in public spaces: We can create images in public spaces that enrich cityscapes and contribute to the creation of an interesting and diverse environment for the public, as well as making public spaces culturally active hubs.
        Event Decoration: With an outdoor printing service, you can also diversify events and activities in outdoor spaces. Whether it's a festival, concert or corporate event, custom printed banners and backdrops will add flair and sophistication to any venue.
        Advantages of UV 3D wall printing:
        Durable: Our prints resist fading, washing and peeling for long-lasting image quality.
        Versatility: Using advanced printing technology, you can print on a variety of surfaces including concrete, glass, metal and wood, allowing for endless possibilities for creative expression.
        Personality: Each project is tailored to our clients' unique vision and requirements, ensuring a precise result that reflects their brand or personality.
        Safety: We give priority to the environmental aspect and use non-toxic paints that are environmentally friendly and compliant with standards.
    `,
        image: `${outside}`
    },
    {
        header: 'მინაზე ხატვა',
        lang: 'GEO',
        text: `ჩვენ ვბეჭდავთ მინის ზედაპირებზეც, რითაც ჩვეულებრივ მინას ვაქცევთ  ვიზუალურად მიმზიდველ ხელოვნების ნიმუშად. აღნიშნული სერვისი შესაძლოა გამოყენებული იყოს ინტერიერის ტიხრების, ვიტრინებისა და ფანჯრების შემთხვევაში. 

ჩვენი კომპანია გთავაზობთ მინებზე ინდივიდუალური დიზაინის გამოსახულებებს, რომლებიც შეიძლება შესრულდეს როგორც შიდა სივრცეებში, ასევეგარე ფასადზე. UV 3D ბეჭდვის ტექნოლოგია საშუალებას გვაძლევს შევქმნათ გასაოცარი გამოსახულებები, რომლებიც ხაზს უსვამენ ნებისმიერი გარემოს ესთეტიკურ მიმზიდველობას.

მინის დაბურვა: შუშის ტიხრებისა და ფანჯრებისთვის გთავაზობთ კონფიდენციალურობის/პრივატულობის დეკორატიულ გადაწყვეტას , რაც უზრუნველჰყოფს დიზაინისა და ფუნქციონალურობის სრულყოფილ ბალანსს. აღნიშნული სერვისის გამოყენება შესაძლებელიასაოფისე ფართებისთვის, საკონფერენციო ოთახებისთვის და საცხოვრებელი ოთახებისთვის.

ბრენდინგი: კომპანიებს შეუძლიათ გამოიყენონ შუშაზე ბეჭდვის სერვისი, რათა გამოკვეთონ ბრენდის იდენტობა და შექმნან ეფექტური რეკლამა. იქნება ეს ლოგო, სლოგანი თუ სარეკლამო შეტყობინება, ჩვენი მაღალი ხარისხის პრინტები პროფესიონალურ ელფერს მატებს ვიტრინებ, ფანჯრებს და ა.შ.

არქიტექტურული გაუმჯობესება: UV 3D ბეჭდვის ტექნოლოგია საშუალებას გვაძლევს გავაუმჯობესოთ შუშის ზედაპირების არქიტექტურული მახასიათებლები, დავამატოთ სიღრმე, ტექსტურა და დიზაინი.
`,
        image: `${mirror}`
    },
    {
        header: 'Роспись по стеклу',
        lang: 'RUS',
        text: `   Мы также печатаем на стеклянных поверхностях, превращая обычное стекло в визуально привлекательное произведение искусства. Данную услугу можно использовать для интерьера, витрин и окон.
        Наша компания предлагает индивидуальное дизайнерское изображение на стекле, которое может быть выполнено как во внутренних помещениях, так и на внешнем фасаде. Технология УФ 3D-печати позволяет создавать удивительные изображения, подчеркивающие эстетическую привлекательность любого помещения.
        Остекление: предлагает декоративное решение для уединения/приватности для стеклянных перегородок и окон, обеспечивая идеальный баланс дизайна и функциональности. Эту услугу можно использовать для офисных помещений, конференц-залов и гостиных.
          Брендинг: компании могут использовать услуги печати на стекле, чтобы подчеркнуть индивидуальность своего бренда и создать эффективную рекламу. Будь то логотип, слоган или рекламное сообщение, наши высококачественные отпечатки придадут профессиональный вид витринам, витринам и т. д.
         Архитектурные улучшения: технология UV-3D-печати позволяет нам улучшить архитектурные особенности стеклянных поверхностей, добавив глубины, текстуры и дизайна.
     `,
        image: `${mirror}`
    },
    {
        header: 'Glass painting',
        lang: 'ENG',
        text: `  We also print on glass surfaces, turning ordinary glass into a visually appealing piece of art. This service can be used for interiors, shop windows and windows.
        Our company offers individual design images on glass, which can be made both in interior spaces and on the exterior facade. UV 3D printing technology allows you to create amazing images that highlight the aesthetic appeal of any room.
        Glazing: Offers a decorative privacy/privacy solution for glass partitions and windows, providing the perfect balance of design and functionality. This service can be used for office spaces, conference rooms and living rooms.
          Branding: Companies can use glass printing services to highlight their brand identity and create effective advertising. Whether it's a logo, slogan or promotional message, our high-quality prints will add a professional look to window displays, window displays and more.
         Architectural Enhancements: UV 3D printing technology allows us to enhance the architectural features of glass surfaces by adding depth, texture and design.
     `,
        image: `${mirror}`
    },
    {
        header: 'ავტომობილზე ხატვა',
        lang:'GEO',
        text: `ბეჭდვის უახლესი მეთოდით  გთავაზობთ  მანქანების გარდაქმნას ხელოვნების თვალწარმტაც ნიმუშებად. 

შემოთავაზებული სერვისები:
სატრანსპორტო საშუალებების დიზაინი: ჩვენი კომპანია მუშაობს სატრანსპორტო საშუალებების დიზაინზე, რომელიც მთლიანად გარდაქმნის მსუბუქი  და სატვირთო მანქანების გარეგნობას. კორპორატიული ავტოპარკის დახვეწილი და პროფესიონალური დიზაინიდან დაწყებული, პერსონალური მანქანების თამამი და ყურადღების მისაპყრობი დიზაინით დამთავრებული, გთავაზობთ შესაძლებლობების ფართო არჩევანს.

3D გრაფიკა: ულტრაიისფერი ბეჭდვის მოწინავე ტექნოლოგიის გამოყენებით, შეგვიძლია შევქმნათ განსაცვიფრებელი 3D გრაფიკა, რომელიც სიღრმესა და განზომილებას მატებს ავტომობილის ვიზუალს. ჩვენი 3D დიზაინი მანქანებს გამორჩეულს ხდის და ქმნის ჭეშმარიტად შთამბეჭდავ ვიზუალურ გამოსახულებას.

ბრენდის პოპულარიზაცია: კომპანიებს შეუძლიათ გამოიყენონ მანქანებზე ბეჭდვის სერვისი ბრენდის პოპულარიზაციის ხელშესაწყობად. ინდივიდუალურად თქვენზე მორგებული დიზაინი და გრაფიკა აქცევს მსუბუქ  და სატვირთო მანქანებს მობილურ ბილბორდებად დაზრდის ბრენდის ხილვადობას.

`,
        image: `${car}`
    },
    {
        header: 'Покраска автомобилей',
        lang: 'RUS',
        text: `С помощью новейшего метода печати мы предлагаем превратить автомобили в эффектные произведения искусства.
        Предлагаемые услуги:
    Дизайн транспортных средств: Наша компания занимается дизайном транспортных средств, который полностью преображает внешний вид легковых и грузовых автомобилей. Изысканный и профессиональный дизайн корпоративного автопарка, а также интересный дизайн личных автомобилей открывают широкие возможности.
    3D-графика: используя передовую технологию УФ-печати, мы можем создавать потрясающую 3D-графику, которая добавляет глубину и объемность визуальному изображению автомобиля. Наши 3D-проекты выделяют автомобили и создают поистине впечатляющий визуальный образ.
    Продвижение бренда: компании могут использовать услуги автомобильной печати для продвижения своего бренда. Индивидуальный дизайн и графика превращают легковые и грузовые автомобили в мобильные рекламные щиты и повышают узнаваемость бренда.
    `,
        image: `${car}`
    },
    {
        header: 'Car painting',
        lang: 'ENG',
        text: `Using the latest printing method, we offer to transform cars into spectacular works of art.
        Services offered:
   Vehicle Design: Our company specializes in vehicle design that completely transforms the appearance of cars and trucks. The sophisticated and professional design of a corporate fleet, as well as the interesting design of personal cars, open up wide possibilities.
   3D Graphics: Using advanced UV printing technology, we can create stunning 3D graphics that add depth and dimension to the visual image of the vehicle. Our 3D designs highlight vehicles and create a truly impressive visual experience.
   Brand Promotion: Companies can use automotive printing services to promote their brand. Custom designs and graphics transform cars and trucks into mobile billboards and increase brand awareness.
   `,
        image: `${car}`
    },
]