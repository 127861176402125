import React from "react";
import './footer.css';
import AboutFooter from "./about.footer";
import SocialFooter from "./social.footer";

const Footer = ({language}) => { 
    return (
        <div className="px-8 py-10 footer">
            <AboutFooter language={language}/>
            <SocialFooter language={ language} />
        </div>
    )
}
export default Footer;