export const aboutFooter = [
    {
        lang: 'GEO',
        text: 'მეტ ელფერს შესძენს თქვენს სახლს ან ოფისს! ფოტო ბეჭდვა ნებისმიერ ვერტიკალურ ზედაპირზე არის ლამაზი და კრეატიული გზა. Გადაეშვით მდიდარი და სრულყოფილი ფერების სამყაროში!',
        address: `
        ატენის ქუჩა 18ბ, 0179, თბილისი, საქართველო
        `
    },
    {
        lang: 'ENG',
        text: 'It will add more color to your home or office! Photo printing on any vertical surface There is a beautiful and creative way. Get down In a world of rich and perfect colors!',
        address: `
        18B Ateni Street, 0179, Tbilisi, Georgia
        `
    },
    {
        lang: 'RUS',
        text: 'добавит больше цвета в ваш дом или офис! Фотопечать на любой вертикальной поверхности. Есть красивый и креативный способ. Спускаться В мире насыщенных и совершенных цветов!',
        address: `
        Улица Атени 18Б, 0179, Тбилиси, Грузия
        `
    },
]