import React from "react";
import { Helmet } from "react-helmet";
import { ServiceData } from "./services.data"; 
import './../../fonts/fonts.css';
import './service.css';
import { useMediaQuery } from "react-responsive";
import keywordsData from './../keywords.json'
const Services = ({ language }) => {
    const bigScreen = useMediaQuery({query: '(max-width: 1460px)'})
    const bigMediumScreen = useMediaQuery({query: '(max-width: 1420px)'})
    const bigMediumScreenSecond = useMediaQuery({ query: '(max-width: 1300px)' })
    const filteredData = ServiceData.filter(item => item.lang === language);  
    const keywords = keywordsData.keywords;
    return (
        <div>
            <Helmet>
                <title>Printwall - {language === 'GEO' ? 'სერვისები' : language === 'ENG' ? 'Services' : 'Услуги'}</title>
                <meta name="description" content="Explore Printwall's comprehensive range of services offering automated drawing solutions for transforming your walls. From custom designs to rapid execution, our services deliver innovative wall art with precision and affordability." />
                <meta name="description" content="Изучите широкий спектр услуг Printwall, предлагающих автоматизированные решения для рисования, которые преобразят ваши стены. От индивидуального дизайна до быстрого выполнения — наши услуги обеспечивают инновационную настенную живопись с точностью и доступностью." />
                <meta name="description" content="გამოიკვლიეთ Printwall-ის სერვისების ყოვლისმომცველი ასორტიმენტი, რომელიც გთავაზობთ ავტომატური ნახაზის გადაწყვეტილებებს თქვენი კედლების გარდაქმნისთვის. მორგებული დიზაინიდან დაწყებული სწრაფ შესრულებამდე, ჩვენი სერვისები აწვდიან ინოვაციურ კედლის ხელოვნებას სიზუსტით და ხელმისაწვდომ ფასად." />
                {keywords.map(keyword => (
                    <meta name="keywords" content={keyword} key={keyword} />
                ))}
            </Helmet>
            {filteredData.map((service) => {
                return (
                    
                    <div key={service.header}>
                        <div className={`${bigScreen ? (bigMediumScreen ? (bigMediumScreenSecond ? 'px-6 py-8' :'px-16 py-10') : 'px-20') : 'px-24' } flex flex-col gap-12 py-16 services `}>
                            <h2>{service.header}</h2>
                            <div className="grid justify-between grid-cols-2 gap-x-8" style={{  alignItems: 'stretch',textAlign:'justify' }}>
                                <p style={bigMediumScreenSecond ? {} : { whiteSpace: 'pre-line' }}>{service.text}</p>
                                <img src={service.image} alt={ service.header}  style={{ width: "100%", height: "100%", objectFit: "cover", objectPosition: "center"}}/>    
                            </div>
                        </div>
                        <div className="info-content-service"></div>
                    </div>
                )
            }) }
        </div>
    );
};

export default Services;