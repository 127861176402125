import HomeSlide1 from "./../../images/homeSlide-1.png"
export const SliderData = [
    {
        image: `${HomeSlide1}`,
        texts: [
            {
                lang: 'GEO',
                ht: 'ბეჭდვა \nკედლებზე',
                text: 'თბილისსა \nდა რეგიონებში',
                link: 'მეტის გაგება'
            },
            {
                lang: 'ENG',
                ht: `Printing 
                on walls`,
                text: `In Tbilisi and regions`,
                link: 'Learn more'
            },
            {
                lang: 'RUS',
                ht: `Печать 
                на стенах `,
                text: `в Тбилиси и регионах...`,
                link: 'Узнать больше'
            },
        ], 
    },  
]