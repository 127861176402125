import React, { useState } from 'react';
import emailjs from 'emailjs-com';
import './form.css';
import { useMediaQuery } from 'react-responsive';

const EmailForm = ({language}) => {

  const isSmallScreen = useMediaQuery({ query: '(max-width: 700px)' });

  const [emailData, setEmailData] = useState({
    to: 'info@printwall.ge', 
    subject: '',
    text: '',
    attachment: null
  });

  const handleInputChange = (e) => {
    setEmailData({ 
      ...emailData, 
      [e.target.name]: e.target.value,
      to: e.target.name === 'from' ? e.target.value : emailData.to 
    });
  };
  const sendAutomatedResponse = async () => {
    const templateParams = {
      from_name: 'printwall.ge', 
      to_name: emailData.name,
      to_email:emailData.from,
      message: 'Thank you for your email. We will get back to you as soon as possible.',
    };
  
    try {
      await emailjs.send('service_lszno4c', 'template_psshtht', templateParams,'xNl3m_WwM5EjrJAs0');
      // console.log('Automated response sent successfully');
    } catch (error) {
      // console.error('Error sending automated response:', error);
      // alert('Error sending automated response. Please try again.');
    }
  };

  const handleSubmit = async (e) => {
    e.preventDefault();

    try {
      const messageToSend = `
        This mail sent from ${emailData.from}
        Name is ${emailData.name}
        Phone Number is ${emailData.phoneNumber}
        Address is ${emailData.subject}
        `
      const templateParams = {
        message: messageToSend
      };
      await emailjs.send('service_lszno4c', 'template_5jeowdr', templateParams, 'xNl3m_WwM5EjrJAs0');
      await sendAutomatedResponse();
      // console.log('Email sent successfully');
      alert('Email sent successfully');
    } catch (error) {
      // console.error('Error sending email:', error);
      // alert('Error sending email. Please try again.');
    }
  };
  return (
        <div className={`flex flex-col items-center w-full ${isSmallScreen ? 'px-20' : 'px-40'} pt-10 form-font`}>
          <h2 className='text-2xl '>{language === 'GEO'?'შეკვეთის ფორმა':language==='ENG'?'Order form':'Форма заказа'}</h2>
          <form onSubmit={handleSubmit} className='flex flex-col items-center w-full mt-10 gap-y-5'>
          <input type="text" name="name" placeholder={`${language === 'GEO' ? 'სახელი და გვარი' : language==='ENG'?'Name and surname':'Имя и фамилия'}`} value={emailData.name} onChange={handleInputChange} required />
            <input type="text" name="subject" placeholder={`${language === 'GEO'? 'შეკვეთის მისამართი':language==='ENG'?'Order Address':'Адрес заказа'}`} value={emailData.subject} onChange={handleInputChange} required />
            <input type="text" name="phoneNumber" placeholder={`${language === 'GEO'? 'ტელეფონი':language==='ENG'?'Telephone':'телефон'}`} value={emailData.phoneNumber} onChange={handleInputChange} required />
            <input type="email" name="from" placeholder={`${language === 'GEO'? 'ელექტრონული ფოსტა':language==='ENG'?'E-mail':'электронная почта'}`} value={emailData.from} onChange={handleInputChange} required />
            <button type="submit" className='w-full py-4 font-bold text-white bg-primary'>{language === 'GEO'?'შეკვეთის გაგზავნა':language==='ENG'?'Send the order':'Отправить заказ'}</button>
            <div className='flex flex-row w-full font-bold gap-x-1 text-primary box-to-check'>
              <span className='check-box'></span>
              <p className='check-box-text'>{language === "GEO" ? 'მე ვაძლევ თანხმობას ჩემი პერსონალური მონაცემების დამუშავებაზე და ვეთანხმები დამუშავების წესებს' : language === 'ENG' ? 'I consent to the processing of my personal data and agree to the processing rules' : 'Я даю согласие на обработку моих персональных данных и согласен с правилами обработки' }</p>
            </div>
          </form>
        </div>
      );
};

export default EmailForm;

