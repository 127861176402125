import React,{useState, useEffect} from "react";
import "./../../App.css"
import "./nav.css"
import { NavLink, useLocation } from "react-router-dom";

// import { useMediaQuery } from "react-responsive";
const Menu = ({language}) => {
    const location = useLocation();
    

    const [toggle, setToggle] = useState(1)

    const [showServicesDropdown, setShowServicesDropdown] = useState(false);
    const [showPortfolioDropdown, setShowPortfolioDropdown] = useState(false);

    const handleMouseEnterServices = () => {
        setShowServicesDropdown(true);
        setShowPortfolioDropdown(false);
    };

    const handleMouseEnterPortfolio = () => {
        setShowServicesDropdown(false);
        setShowPortfolioDropdown(true);
    };

    const handleMouseLeave = () => {
        setShowServicesDropdown(false);
        setShowPortfolioDropdown(false);
    };

    const tabToggle = (index) => {
        setToggle(index);
        setShowServicesDropdown(false);
        setShowPortfolioDropdown(false);
    };

    useEffect(() => {
        const pathNames = {
            "/": 1,
            "/About": 2,
            "/Services": 3,
            "/Services/PrintOnWall": 3.1,
            "/Services/PrintOutsideWall": 3.2,
            "/Services/PrintMirror": 3.3,
            "/Services/AutoPrint": 3.4,
            "/Portfolio": 4,
            "/Portfolio/Offices": 4.1,
            "/Portfolio/CafeAndBars": 4.2,
            "/Portfolio/HotelsAndHostels": 4.3,
            "/Portfolio/Schools": 4.4,
            "/Portfolio/Bedrooms": 4.5,
            "/Portfolio/Houses": 4.6,
            "/Portfolio/Rooms": 4.7,
            "/Prices": 5,
            "/Questions": 6,
            "/Contacts": 7
        };
        setToggle(pathNames[location.pathname])
    }, [location.pathname])
    useEffect(() => {
        localStorage.setItem("language", language);
    }, [language]);

    return (
        <ul className="flex flex-row lex gap-x-8">
            
            <li><NavLink rel='canonical' to="/" className={toggle === 1 ? 'active ' : ''} onClick={() => tabToggle(1)}>{language === 'GEO' ? 'მთავარი' : language === 'ENG' ? 'Main' : 'Главная'}</NavLink></li>
            <li><NavLink rel='canonical' to="/About" className={toggle === 2 ? 'active' : ''} onClick={() => tabToggle(2)}>{language === 'GEO' ? 'კომპანიის შესახებ' : language === 'ENG' ? 'About company' : 'О компании'}</NavLink></li>
            
            <li className="dropdown"   onMouseEnter={handleMouseEnterServices} onMouseLeave={handleMouseLeave}>
                <NavLink rel='canonical' to="/Services" className={toggle === 3 ? 'active drop-service cursor-pointer' : 'drop-service cursor-pointer'} onClick={() => { tabToggle(3)}}>{language === 'GEO' ? ' სერვისები' : language === 'ENG' ? 'Services' : 'Услуги'}</NavLink>
                {showServicesDropdown && (

                    <ul className="drop-menu" >
                        <li><NavLink rel='canonical' to="/Service/PrintOnWall" className={toggle === 3.1 ? 'active ' : 'text-white  nav-link'} onClick={() => tabToggle(3)}>{language === 'GEO' ? 'კედლებზე ხატვა' : language === 'ENG' ? 'Painting on the walls' : 'Роспись на стенах'}</NavLink></li>
                        <li><NavLink rel='canonical' to="/Service/PrintOutsideWall" className={toggle === 3.2 ? 'active' : 'text-white nav-link'} onClick={() => tabToggle(3)}>{language === 'GEO' ? 'გარე ფასადზე ხატვა' : language === 'ENG' ? 'Painting of external facades' : 'Покраска наружных фасадов'}</NavLink></li>
                        <li><NavLink rel='canonical' to="/Service/PrintMirror" className={toggle === 3.3 ? 'active ' : 'text-white nav-link'} onClick={() => tabToggle(3)}>{language === 'GEO' ? 'მინაზე ხატვა' : language === 'ENG' ? 'Glass painting' : 'Роспись по стеклу'}</NavLink></li>
                        <li><NavLink rel='canonical' to="/Service/AutoPrint" className={toggle === 3.4 ? 'active' : 'text-white nav-link'} onClick={() => tabToggle(3)}>{language === 'GEO' ? 'ავტომობილებზე ხატვა' : language === 'ENG' ? 'Car painting' : 'Покраска автомобилей'}</NavLink></li>
                    </ul>
                )}
                
                
            </li>    
            <li className="dropdown" onMouseEnter={handleMouseEnterPortfolio} onMouseLeave={handleMouseLeave}>
                <NavLink rel='canonical' to="/Order" className={toggle === 4 ? 'active drop-portfolio cursor-pointer' : 'drop-portfolio cursor-pointer'} onClick={() => { tabToggle(4)}}>{language === 'GEO' ? 'შეთავაზებები' : language === 'ENG' ? 'Offers' : 'Предложения'}</NavLink>
                {showPortfolioDropdown && (
                    <ul className='drop-menu w-fit' >
                        <li><NavLink rel='canonical' to="/Order/Offices" className={toggle === 4.1 ? 'active' : 'text-white nav-link'} onClick={() => tabToggle(4)}>{language === 'GEO' ? 'ოფისები' : language === 'ENG' ? 'Offices' : 'офисы'}</NavLink></li>
                        <li><NavLink rel='canonical' to="/Order/CafeAndBars" className={toggle === 4.2 ? 'active' : 'text-white nav-link'} onClick={() => tabToggle(4)}>{language === 'GEO' ? 'რესტორნები ' : language === 'ENG' ? 'Restaurants' : 'Рестораны'}</NavLink></li>
                        {/* <li><NavLink to="/Order/HotelsAndHostels" className={toggle === 4.3 ? 'active' : ''} onClick={() => tabToggle(4)}>სასტუმროები და ჰოსტელები</NavLink></li> */}
                        <li><NavLink rel='canonical' to="/Order/Schools" className={toggle === 4.4 ? 'active' : 'text-white nav-link'} onClick={() => tabToggle(4)}>{language === 'GEO' ? 'სასწავლო დაწესებულებები' : language === 'ENG' ? 'Educational institutions' : 'образовательные учреждения'}</NavLink></li>
                        <li><NavLink rel='canonical' to="/Order/kidrooms" className={toggle === 4.5 ? 'active' : 'text-white nav-link'} onClick={() => tabToggle(4)}>{language === 'GEO' ? 'ბავშვის ოთახი' : language === 'ENG' ? "Child's room" : 'детская комната'}</NavLink></li>
                        <li><NavLink rel='canonical' to="/Order/Houses" className={toggle === 4.6 ? 'active' : 'text-white nav-link'} onClick={() => tabToggle(4)}>{language === 'GEO' ? 'კერძო ბინები' : language === 'ENG' ? 'Houses and flats' : 'дома и квартиры'}</NavLink></li>
                        <li><NavLink rel='canonical' to="/Order/Rooms" className={toggle === 4.7 ? 'active' : 'text-white nav-link'} onClick={() => tabToggle(4)}>{language === 'GEO' ? 'საძინებლები' : language === 'ENG' ? 'Bedrooms' : 'спальни'}</NavLink></li>
                        <li><NavLink rel='canonical' to="/Order/Autos" className={toggle === 4.7 ? 'active' : 'text-white nav-link'} onClick={() => tabToggle(4)}>{language === 'GEO' ? 'ავტომობილები' : language === 'ENG' ? 'Cars' : 'автомобили'}</NavLink></li>
                    </ul>
                )}
            </li>
            <li><NavLink rel='canonical' to="/Prices" className={toggle === 5 ? 'active' : ''} onClick={() => tabToggle(5)}>{language === 'GEO' ? 'ფასები' : language === 'ENG' ? 'Pricelist' : 'Цены'}</NavLink></li>
            <li><NavLink rel='canonical' to="/Questions" className={toggle === 6 ? 'active' : ''} onClick={() => tabToggle(6)}>{language === 'GEO' ? 'კითხვა-პასუხი' : language === 'ENG' ? 'FAQs' : 'Вопросы и ответы'}</NavLink></li>
            <li><NavLink rel='canonical' to="/Contacts" className={toggle === 7 ? 'active' : ''} onClick={() => tabToggle(7)}>{language === 'GEO' ? ' კონტაქტი' : language === 'ENG' ? 'Contact' : 'Контакт'}</NavLink></li>
        </ul>
    )
}

export default Menu;