import React, { useState } from "react";
import imageFilenames from './../../imageFilenames.json';
import { useMediaQuery } from "react-responsive";
import Modal from 'react-modal';
import { Helmet } from "react-helmet";
import keywordsData from './../keywords.json'
const Portfolio = ({language}) => {
    const bigScreen = useMediaQuery({ query: '(max-width: 1424px)' });
    const mediaScreen = useMediaQuery({ query: '(max-width:1250px)' });
    const smallScreen = useMediaQuery({ query: '(max-width:700px)' });

    const [selectedImage, setSelectedImage] = useState(null);
    const [modalOpen, setModalOpen] = useState(false);

    const openModal = (filename) => {
        setSelectedImage(filename);
        setModalOpen(true);
    };

    const closeModal = () => {
        setSelectedImage(null);
        setModalOpen(false);
    };
    const keywords = keywordsData.keywords;
    return (
        <section className={`flex flex-wrap gap-y-10 py-16 justify-between ${bigScreen ? (mediaScreen ? (smallScreen ? '' : 'px-9') : 'px-9') : 'px-9'}`}>
            <Helmet>
                <title>Printwall - {language === 'GEO'?'შეთავაზებები' :language === 'ENG'?'Offers':'Предложения' }</title>
                <meta name="description" content="Explore Printwall's stunning gallery of wall drawings created with precision and efficiency by our innovative machines. Discover affordable wall art solutions that surpass hand-drawn designs in speed and quality." />
                <meta name="description" content="Изучите потрясающую галерею настенных рисунков Printwall, созданных с точностью и эффективностью на наших инновационных машинах. Откройте для себя доступные решения для настенного искусства, которые превосходят нарисованные от руки рисунки по скорости и качеству." />
                <meta name="description" content="გამოიკვლიეთ Printwall-ის კედლის ნახატების განსაცვიფრებელი გალერეა, რომელიც შექმნილია ჩვენი ინოვაციური მანქანების მიერ სიზუსტით და ეფექტურობით. აღმოაჩინეთ ხელმისაწვდომ ფასად კედლის ხელოვნების გადაწყვეტილებები, რომლებიც აღემატება ხელით დახატულ დიზაინებს სიჩქარითა და ხარისხით." />
                {keywords.map(keyword => (
                    <meta name="keywords" content={keyword} key={keyword} />
                ))}
            </Helmet>
            {imageFilenames.map((filename, index) => ( 
                <div key={index}>
                    <img src={`./portfolioimages/${filename}`} alt={`Portfolio ${index + 1}`} onClick={() => openModal(filename)} style={!mediaScreen ? { width: "460px", height: "320px" } : {width: "200px", height: "140px"}} />
                </div>
            ))}
            <Modal
                isOpen={modalOpen}
                onRequestClose={closeModal}
                contentLabel="Image Popup"
                style={{
                    content: {
                        position: 'absolute',
                        margin: '80px auto',
                        width: 'fit-content',
                        height: 'fit-content',
                        zIndex: '1000'
                    }
                }}
            >
                <button onClick={closeModal}>Close</button>
                {selectedImage && <img src={`./portfolioimages/${selectedImage}`} alt="Popup" style={modalOpen ? { width: "720px", height: "560px"} : { width: "400px", height: "300px" }} />}
            </Modal>
        </section>
    );
};

export default Portfolio;
