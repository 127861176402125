export const QuestionsData = [
    {
        subheader: `როგორ მიმდინარეობს სამუშაო პროცესი?`,
        lang:'GEO',
        text: 'ბეჭდვისთვის ხელსაყრელი დღე და დრო უნდა შეთანხმდეს წინასწარ. პრინტერის აღჭურვილობის  დამონტჟებისა და დაკონფიგურირების შემდეგ აიტვირთება თქვენ მიერ არჩეული სურათი და დაიწყება ბეჭდვის პროცესი. ბეჭდვის დასრულების შემდეგ დასუფთავდება სამუშაო ადგილი. პრინტერს ზედამხედველობს კვალიფიციური ტექნიკოსი, ის უზრუნველყოფს სამუშაო პროცესის თავიდან ბოლომდე სტანდარტების მიხედვით მიმდინარეობას.'
    },
    {
        subheader: `Как проходит рабочий процесс?`,
        lang:'RUS',
        text: 'Удобный день и время для печати необходимо согласовать заранее. После того, как принтерное  оборудование будет установлено и настроено, выбранное вами изображение будет загружено и начнется процесс печати. После печати рабочее место будет убрано. Принтер контролируется квалифицированным техническим специалистом. Это гарантирует, что рабочий процесс проходит в соответствии со стандартами от начала до конца.'
    },
    {
        subheader: `How is the workflow?`,
        lang:'ENG',
        text: 'A favorable day and time for printing should be agreed in advance. After the printer equipment is  installed and configured, the image you selected will be uploaded and the printing process will begin. After printing, the workplace will be cleaned. The printer is supervised by a qualified technician. It ensures that the workflow runs according to standards from start to finish.'
    },
    {
        subheader: `რომელი სურათი აირჩიოთ დასაბეჭდად?`,
        lang:'GEO',
        text: 'პრინტერის შესაძლებლობები შეუზღუდავია, ამიტომ არჩევანი შემოიფარგლება მხოლოდ თქვენი  ფანტაზიით. ჩვენთან შესაძლებელია სარეკლამო შინაარსის სურათების ან კორპორატიული სიმბოლოების ბეჭდვა, ასევე ბინის, ოფისის, საბავშვო ბაღის, სკოლის ან კლინიკის ინტერიერის გაფორმება. კედელზე შეიძლება დავიტანოთ, როგორც ფოტო ან გამოსახულება ინტერნეტიდან, ასევე ფანქრით, აკვარელით და სხვა მასალებით შესრულებული ეფექტის მქონე სურათები. საკმარისია აირჩიოთ გამოსახულების სიგრძე, სიგანე და მიუთითოთ ადგილი, სადაც გსურთ აღნიშნული დიზაინის ბეჭდვა. დანარჩენს თავად პრინტერი შეასრულებს. ჩვენ მზად ვართ განვიხილოთ თქვენი ნებისმიერი იდეა .'

    },
    {
        subheader: `What image to choose for printing?`,
        lang:'ENG',
        text: 'The possibilities of the printer are unlimited, so the choice is limited only by your imagination. We can print advertising content images or corporate symbols. Also interior decoration of an apartment, office, kindergarten, school or clinic. A photo or an image from the Internet, as well as pictures made with pencil, watercolor and other materials can be placed on the wall. All you have to do is choose the length, width of the image and specify the place where you want to print the said design. The printer itself will do the rest. We are ready to consider any of your ideas.'

    },
    {
        subheader: `Какое изображение выбрать для печати?`,
        lang:'RUS',
        text: 'Возможности принтера безграничны, поэтому выбор ограничивается только вашей фантазией. Мы можем напечатать изображения рекламного содержания или корпоративную символику. А также внутренняя отделка квартиры, офиса, детского сада, школы или поликлиники. На стене можно разместить фотографию или изображение из Интернета, а также картинки, выполненные карандашом, акварелью и другими материалами. Все, что вам нужно сделать, это выбрать длину, ширину изображения и указать место, где вы хотите распечатать указанный дизайн. Все остальное принтер сделает сам. Мы готовы рассмотреть любые ваши идеи.'

    },
    {
        subheader: `საჭიროა თუ არა ჩემი ჩართულობა და რამდენი ხანი გრძელდება ხატვა?`,
        lang:'GEO',
        text: 'თქვენ  რაიმე ფორმით პროცესში ჩართულობაარ არის საჭირო.პრინტერი მუშაობს სწრაფად - მიახლოებითი სიჩქარე 5-15მ2 საათში. მიიღებთ საუკეთესო მომსახურებას და ისიამოვნებთ შედეგით პროცესის დასრულებისთანავე.'
    },
    {
        subheader: `Is it necessary to involve me in the process and how long does the printing take?`,
        lang:'ENG',
        text: 'You do not need to be involved in the process in any way. The printer works fast - speed 5-15 m2 per hour. You will get the best service and enjoy the results as soon as the process is over.'
    },
    {
        subheader: `Нужно ли меня привлекать к процессу и сколько времени занимает печать?`,
        lang:'RUS',
        text: 'Вам не нужно никоим образом участвовать в процессе. Принтер работает быстро – скорость 5-15 м2 в час. Вы получите лучший сервис и насладитесь результатами сразу после завершения процесса.'
    },
    {
        subheader: `რა არის თქვენი პრინტერის უპირატესობა?`,
        lang:'GEO',
        text: 'ჩვენ ვიყენებთ ვერტიკალურ პრინტერს ულტრაიისფერი ბეჭდვის ტექნოლოგიით,Epson-ის ბეჭდვის თავით და ბეჭდვის მაქსიმალური გარჩევადობით 720x2880dpi, რაც მსგავს მოწყობილობებს შორის საუკეთესო შესრულების შესაძლებლობას იძლევა.'
    },
    {
        subheader: `Каковы ваши предпочтения в отношении принтера?`,
        lang:'RUS',
        text: 'Мы используем вертикальный принтер с технологией UV-печати, системой печати Epson и максимальным разрешением 720x2880dpi, что является лучшим среди аналогичных устройств.'
    },
    {
        subheader: `What is your printer preference?`,
        lang: 'ENG',
        text: 'We use a vertical printer with UV printing technology, Epson printing system and a maximum resolution of 720x2880dpi, which is the best among similar devices'
    },
    {
        subheader: `What surfaces does the printer print on?`,
        lang: 'ENG',
        text: 'The printer is designed for vertical printing on any surface. Printing is possible on walls, glass, wood, metal and ceramics.'
    },
    {
        subheader:`რა ზედაპირებზე ბეჭდავს პრინტერი?`,
        lang:'GEO',
        text: 'პრინტერი განკუთვნილია ნებისმიერ ზედაპირზე ვერტიკალური ბეჭდვისთვის. ბეჭდვა  შესაძლებელია კედელზე, მინაზე, ხეზე ,მეტალსა და კერამიკაზე.'
    },
    {
        subheader:`На каких поверхностях печатает принтер?`,
        lang:'RUS',
        text: 'Принтер предназначен для вертикальной печати на любой поверхности. Печать возможна на стенах, стекле, дереве, металле и керамике.'
    },
    {
        subheader: `შეუძლია თუ არა პრინტერს არათანაბარი ზედაპირის პირობებში ბეჭდვა ?`,
        lang:'GEO',
        text: 'პრინტერი ავტომატურად არეგულირებს კედელზე არსებულ ნებისმიერ უთანასწორობას და ბეჭდავს სურათს სწორად . მთავარია საბეჭდ ზედაპირზე არ იყოს წინაღობები (მაგ. საკეტები, ჩამრთველები და ა.შ.).'
    },
    {
        subheader: `Can the printer print on uneven surfaces?`,
        lang:'ENG',
        text: 'The printer automatically adjusts for any unevenness on the wall and prints the image correctly. The main thing is that there are no obstacles on the printing surface (eg locks, switches, etc.)'
    },
    {
        subheader: `Может ли принтер печатать на неровных поверхностях?`,
        lang:'RUS',
        text: 'Принтер автоматически адаптируется к любым неровностям стены и правильно печатает изображение. Главное, чтобы на печатной поверхности не было препятствий (например, замков, выключателей и т.п.).'
    },
    {
        subheader: `შესაძლებელია გარე ფასადზე ბეჭდვა?`,
        lang:'GEO',
        text: 'შენობის გარე კედელზე გამოსახულების ბეჭდვა შესაძლებელია.'
    },
    {
        subheader: `Is it possible to print on the exterior facade?`,
        lang:'ENG',
        text: 'The image can be printed on the outer wall of the building.'
    },
    {
        subheader: `Возможна ли печать на внешнем фасаде?`,
        lang:'RUS',
        text: 'Изображение можно напечатать на внешней стене здания.'
    },
    {
        subheader: `რა გარანტიებს იძლევით?`,
        lang:'GEO',
        text: 'შენობის შიდა სივრცეში შესრულებელ ნამუშევარზე ვრცელდება 10 წლიანი, ხოლო გარე ფასადის ნამუშევარზე - 5 წლიანი გარანტია.'
    },
    {
        subheader: `What guarantees do you provide?`,
        lang:'ENG',
        text: 'A 10-year warranty applies to the work performed in the interior of the building, and a 5-year warranty to the work on the exterior facade.'
    },
    {
        subheader: `Какие гарантии вы предоставляете?`,
        lang:'RUS',
        text: 'Гарантия 10 лет распространяется на работы, выполняемые внутри здания, и гарантия 5 лет на работы на внешнем фасаде.'
    },
    {
        subheader: `რამდენად უსაფრთხოა სარებავები?`,
        lang:'GEO',
        text: 'საღებავები სრულიად უსაფრთხოა ადამიანებისა და ცხოველებისთვის. საჭიროების შემთხვევაში, წარმოგიდგენთ უსაფრთხოების დამადასტურებელ სერთიფიკატებს.'
    },
    {
        subheader: `How safe are paints?`,
        lang:'ENG',
        text: 'Paints are completely safe for humans and animals. If necessary, we will present security certificates.'
    },  
    {
        subheader: `Насколько безопасны краски?`,
        lang:'RUS',
        text: 'Краски полностью безопасны для человека и животных. При необходимости предоставим сертификаты безопасности.'
    }  
]