import React from "react";
import logo from "./printlogo1.png";
import { NavLink } from "react-router-dom";

const NavbarImage = () => { 
    return (
        
        <NavLink rel='canonical' to="/">

        <img src={logo} alt="logo"/>
        </NavLink>
    )
}

export default NavbarImage;