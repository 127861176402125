// import React, { useEffect, useState } from 'react';
// import { useMediaQuery } from 'react-responsive';
// import imageFilenames from './../../imageFilenames.json';
// import "./home.css";

// const Offer = ({language}) => {
//     const [currentSlider, setCurrentSlider] = useState(0);
//     const lengthImage = imageFilenames.slice(0,6);
//     useEffect(() => {
//         const slideInterval = setInterval(() => {
//             setCurrentSlider(prevSlider => (prevSlider + 1) % lengthImage.length);
//         }, 5000);
//         return () => clearInterval(slideInterval);
//     }, [currentSlider]);

//     const smallScreen = useMediaQuery({ query: '(max-width:700px)' });
//     const bigsmallScreen = useMediaQuery({ query: '(min-width:1200px)' });
//     const numberOfSlides = smallScreen ? 2 : 3;

//     return (
//         <div className={`flex flex-col ${smallScreen ? 'px-8 py-10' : 'px-24 py-16'}  info-content gap-y-14 offer-font`}>
//             <h3 className='text-2xl text-center text-white'>{language === 'GEO' ? 'ჩვენი კომპანიის შეთავაზებები' : language === 'ENG' ? 'Our company offers' : 'Наша компания предлагает'}</h3>
//             <div className='flex flex-row gap-x-5'>
//                 {[...Array(numberOfSlides)].map((_, i) => {
//                     const index = (currentSlider + i) % lengthImage.length;
//                     return (
//                         <div key={index} style={{ display: 'block' }}>
//                             <img src={`/portfolioimages/${lengthImage[index]}`} alt="slide" style={!bigsmallScreen ? {width:'600px',height:'300px'} :  {width:'600px',height:'400px'}}/>
//                         </div>
//                     );
//                 })}
//             </div>
//         </div>
//     )
// }
// export default Offer;
import React, { useEffect, useState } from 'react';
import { useMediaQuery } from 'react-responsive';
import imageFilenames from './../../imageFilenames.json';
import "./home.css";

const Offer = ({ language }) => {
    const [currentSlider, setCurrentSlider] = useState(0);
    const lengthImage = imageFilenames.slice(0, 5); // Select the first 5 images

    useEffect(() => {
        const slideInterval = setInterval(() => {
            setCurrentSlider(prevSlider => (prevSlider + 1) % lengthImage.length);
        }, 5000);
        return () => clearInterval(slideInterval);
    }, [currentSlider]);

    const smallScreen = useMediaQuery({ query: '(max-width:700px)' });
    const bigSmallScreen = useMediaQuery({ query: '(min-width:1200px)' });
    const numberOfSlides = smallScreen ? 2 : 3;

    const handleDotClick = (index) => {
        setCurrentSlider(index);
    };

    return (
        <div className={`flex flex-col ${smallScreen ? 'px-8 py-10' : 'px-24 py-16'} info-content gap-y-14 offer-font`}>
            <h3 className='text-2xl text-center text-white'>{language === 'GEO' ? 'ჩვენი კომპანიის შეთავაზებები' : language === 'ENG' ? 'Our company offers' : 'Наша компания предлагает'}</h3>
            <div className='flex flex-row gap-x-5'>
                {[...Array(numberOfSlides)].map((_, i) => {
                    const index = (currentSlider + i) % lengthImage.length;
                    return (
                        <div key={index} style={{ display: 'block' }}>
                            <img src={`/portfolioimages/${lengthImage[index]}`} alt="slide" style={!bigSmallScreen ? { width: '600px', height: '300px' } : { width: '600px', height: '400px' }} />
                        </div>
                    );
                })}
            </div>
            <div className='dot-container'>
                <div className="dots">
                    {lengthImage.map((_, index) => (
                        <span key={index} className={`dot ${index === currentSlider ? 'active' : ''}`} onClick={() => handleDotClick(index)}></span>
                    ))}
                </div>
            </div>
        </div>
    )
}

export default Offer;
