// import React from "react";
// import NavbarImage from "./image";
// import Menu from "./Menu";
// import "./nav.css"
// import './../../App.css';
// import { NavLink, useLocation } from "react-router-dom";
// import { useMediaQuery } from "react-responsive";
// // import BurgerMenu from "./Burger";
// import { useEffect, useState } from "react";
// import LanguageDropdown from "./languageDropDown";
// const Navbar = ({ onLanguageChange }) => {

//     const location = useLocation();

//     const [toggle, setToggle] = useState(1)

//     const [isOpen, setIsOpen] = useState(false)
//     const [language, setLanguage] = useState(() => {
//         const storedLanguage = localStorage.getItem('language');
//         return storedLanguage ? storedLanguage : 'GEO';
//       });
//     useEffect(() => {
//         const pathNames = {
//             "/": 1,
//             "/About": 2,
//             "/Services": 3,
//             "/Portfolio": 4,
//             "/Prices": 5,
//             "/Questions": 6,
//             "/Contacts": 7
//         };
//         setToggle(pathNames[location.pathname])
//     }, [location.pathname])

//     const tabToggle = (index) => {
//         setToggle(index)
//     }

    
    

//     const [sticky, setSticky] = useState('');
    
//     useEffect(() => {
//         const handleSticky = () => {
//             const offset = window.scrollY;
//             setSticky(offset > 200)
//         }

//         window.addEventListener("scroll", handleSticky)

//         return () => {
//             window.removeEventListener("scroll", handleSticky)
//         }
//     },[])

//     const isBigScreen = useMediaQuery({ query: '(min-width: 1320px)' })
//     // const smallScreen = useMediaQuery({ query: '(max-width: 700px)' })
//     // const [language, setLanguage] = useState('GEO');
    
//     const handleLanguageChange = (lang) => {
//         setLanguage(lang);
//         onLanguageChange(lang);
//       };

//     return (
//         <div className={`transition-all ${sticky ? 'fixed h-fit w-full bg-white z-50 ' : '' }`}>

//         <div className={`top-0 right-0 flex px-9 py-10 items-center justify-between nav-bar `}>
//                 <NavbarImage />
//                 {
//                     isBigScreen ?
//                         <>

//                         <Menu language={language} />
//                         {/* <div className='language-selector'>
//                             <button onClick={() => handleLanguageChange('GEO')} className={language === 'GEO' ? 'active' : ''}>GEO/</button>
//                             <button onClick={() => handleLanguageChange('eng')} className={language === 'eng' ? 'active' : ''}>ENG/</button>
//                             <button onClick={() => handleLanguageChange('ru')} className={language === 'ru' ? 'active' : ''}>RUS</button>
//                         </div> */}
//                         <LanguageDropdown language={language} onLanguageChange={onLanguageChange} />
//                         </>
//                         : <>
//                         <div className={`z-10 h-full ${isOpen ? 'burger-menu fixed w-52 right-0 top-0 block h-full':''} `}>
//             <div className={`flex flex-col w-full gap-y-5 items-center ${isOpen ? 'absolute top-16' : ''}`}>

//             <div className={`${!isOpen ?'flex flex-col gap-1':'flex items-end justify-start'}  cursor-pointer w-fit`} onClick={()=>setIsOpen(!isOpen)}>
//                 <span className={`w-8 h-1 bg-black ${isOpen ? 'rotate-45 absolute' : ''}`}></span>
//                 <span className={`w-8 h-1 bg-black ${isOpen ? '-rotate-45 absolute' : ''}`}></span>
//                 <span className={`w-8 h-1 bg-black ${isOpen ? 'hidden' : ''}`}></span>
//             </div>
        
//             <ul className={`flex flex-col w-full ${!isOpen ? 'hidden' : 'px-4 py-6'} gap-y-2`}>
//                 <li><NavLink rel='canonical' to="/" className={toggle === 1 ? 'active ' : ''} onClick={() => {tabToggle(1);setIsOpen(false)}}>{language === 'GEO' ? 'მთავარი' : language === 'eng' ? 'Main' : 'Главная'}</NavLink></li>
//                 <li><NavLink rel='canonical' to="/About" className={toggle === 2 ? 'active' : ''} onClick={() => {tabToggle(2);setIsOpen(false)}}>{language === 'GEO' ? 'კომპანიის შესახებ' : language === 'eng' ? 'About company' : 'О компании'}</NavLink></li>
//                 <li><NavLink rel='canonical' to="/Services" className={toggle === 3 ? 'active' : ''} onClick={() => {tabToggle(3);setIsOpen(false)}}>{language === 'GEO' ? ' სერვისები' : language === 'eng' ? 'Services' : 'Услуги'}</NavLink></li>
//                 <li><NavLink rel='canonical' to="/Order" className={toggle === 4 ? 'active' : ''} onClick={() => {tabToggle(4);setIsOpen(false)}}>{language === 'GEO' ? 'შეთავაზებები' : language === 'eng' ? 'Offers' : 'Предложения'}</NavLink></li>
//                 <li><NavLink rel='canonical' to="/Prices" className={toggle === 5 ? 'active' : ''} onClick={() => {tabToggle(5);setIsOpen(false)}}>{language === 'GEO' ? 'ფასები' : language === 'eng' ? 'Prices' : 'Цены'}</NavLink></li>
//                 <li><NavLink rel='canonical' to="/Questions" className={toggle === 6 ? 'active' : ''} onClick={() => {tabToggle(6);setIsOpen(false)}}>{language === 'GEO' ? 'კითხვა-პასუხი' : language === 'eng' ? 'Questions and answers' : 'Вопросы и ответы'}</NavLink></li>
//                 <li><NavLink rel='canonical' to="/Contacts" className={toggle === 7 ? 'active' : ''} onClick={() => { tabToggle(7); setIsOpen(false) }}>{language === 'GEO' ? ' კონტაქტი' : language === 'eng' ? 'Contact' : 'Контакт'}</NavLink></li>
//                 <div className='language-selector'>
//                     <button onClick={() => handleLanguageChange('GEO')} className={language === 'GEO' ? 'active' : ''}>GEO/</button>
//                     <button onClick={() => handleLanguageChange('eng')} className={language === 'eng' ? 'active' : ''}>ENG/</button>
//                     <button onClick={() => handleLanguageChange('ru')} className={language === 'ru' ? 'active' : ''}>RUS</button>
//                 </div>
//                 </ul>
//             </div>
            
//         </div>
//                         </>
//                 }
                
//         </div>
//         <div className='gradient-bg'></div>
//         </div>
//     )
// }

// export default Navbar;

import React, { useEffect, useState } from "react";
import NavbarImage from "./image";
import Menu from "./Menu";
import "./nav.css"
import './../../App.css';
import { NavLink, useLocation } from "react-router-dom";
import { useMediaQuery } from "react-responsive";
import LanguageDropdown from "./languageDropDown"; // Import the LanguageDropdown component

const Navbar = ({ onLanguageChange }) => {   
    const location = useLocation();
    const [toggle, setToggle] = useState(1)
    const [isOpen, setIsOpen] = useState(false)
    const [language, setLanguage] = useState(() => {
        const storedLanguage = localStorage.getItem('language');
        return storedLanguage ? storedLanguage : 'GEO';
    });

    useEffect(() => {   
        const pathNames = {
            "/": 1,
            "/About": 2,
            "/Services": 3,
            "/Portfolio": 4,
            "/Prices": 5,
            "/Questions": 6,
            "/Contacts": 7
        };
        setToggle(pathNames[location.pathname])
    }, [location.pathname])

    const tabToggle = (index) => {
        setToggle(index)
    }

    const [sticky, setSticky] = useState('');
    
    useEffect(() => { 
        const handleSticky = () => {
            const offset = window.scrollY;
            setSticky(offset > 200)
        }

        window.addEventListener("scroll", handleSticky)

        return () => {
            window.removeEventListener("scroll", handleSticky)
        }
    },[])

    const isBigScreen = useMediaQuery({ query: '(min-width: 1320px)' })
    
    const handleLanguageChange = (lang) => {
        setLanguage(lang);
        onLanguageChange(lang);
    };

    return (
        <div className={`transition-all ${sticky ? 'fixed h-fit w-full bg-white z-50 ' : '' }`}>
            <div className={`top-0 right-0 flex px-9 py-10 items-center justify-between nav-bar `}>
                <NavbarImage />
                {isBigScreen ?
                    <>
                        <Menu language={language} />
                        <LanguageDropdown language={language} onLanguageChange={handleLanguageChange} />
                    </>
                    :
                    <div className={`z-10 h-full ${isOpen ? 'burger-menu fixed w-52 right-0 top-0 block h-full':''} `}>
                        <div className={`flex flex-col w-full gap-y-5 items-center ${isOpen ? 'absolute top-16' : ''}`}>
                            <div className={`${!isOpen ?'flex flex-col gap-1':'flex items-end justify-start'} language-drop  cursor-pointer w-fit`} onClick={() => setIsOpen(!isOpen)}>
                                <p className={`w-8 h-1 bg-black ${isOpen ? 'rotate-45 absolute' : ''}`}></p>
                                <p className={`w-8 h-1 bg-black ${isOpen ? '-rotate-45 absolute' : ''}`}></p>
                                <p className={`w-8 h-1 bg-black ${isOpen ? 'hidden' : ''}`}></p>
                            </div>
                            <ul className={`flex flex-col w-full ${!isOpen ? 'hidden' : 'px-4 py-6'} gap-y-2`}>
                                <li><NavLink rel='canonical' to="/" className={toggle === 1 ? 'active ' : ''} onClick={() => {tabToggle(1);setIsOpen(false)}}>{language === 'GEO' ? 'მთავარი' : language === 'ENG' ? 'Main' : 'Главная'}</NavLink></li>
                                <li><NavLink rel='canonical' to="/About" className={toggle === 2 ? 'active' : ''} onClick={() => {tabToggle(2);setIsOpen(false)}}>{language === 'GEO' ? 'კომპანიის შესახებ' : language === 'ENG' ? 'About company' : 'О компании'}</NavLink></li>
                                <li><NavLink rel='canonical' to="/Services" className={toggle === 3 ? 'active' : ''} onClick={() => {tabToggle(3);setIsOpen(false)}}>{language === 'GEO' ? ' სერვისები' : language === 'ENG' ? 'Services' : 'Услуги'}</NavLink></li>
                                <li><NavLink rel='canonical' to="/Order" className={toggle === 4 ? 'active' : ''} onClick={() => {tabToggle(4);setIsOpen(false)}}>{language === 'GEO' ? 'შეთავაზებები' : language === 'ENG' ? 'Offers' : 'Предложения'}</NavLink></li>
                                <li><NavLink rel='canonical' to="/Prices" className={toggle === 5 ? 'active' : ''} onClick={() => {tabToggle(5);setIsOpen(false)}}>{language === 'GEO' ? 'ფასები' : language === 'ENG' ? 'Prices' : 'Цены'}</NavLink></li>
                                <li><NavLink rel='canonical' to="/Questions" className={toggle === 6 ? 'active' : ''} onClick={() => {tabToggle(6);setIsOpen(false)}}>{language === 'GEO' ? 'კითხვა-პასუხი' : language === 'ENG' ? 'Questions and answers' : 'Вопросы и ответы'}</NavLink></li>
                                <li><NavLink rel='canonical' to="/Contacts" className={toggle === 7 ? 'active' : ''} onClick={() => { tabToggle(7); setIsOpen(false) }}>{language === 'GEO' ? ' კონტაქტი' : language === 'ENG' ? 'Contact' : 'Контакт'}</NavLink></li>
                            </ul>
                        </div>
                    </div>
                }
            </div>
            <div className='gradient-bg'></div>
        </div>
    )
}

export default Navbar;
