import React, { useState, useEffect } from 'react';
import Navbar from './components/navbar/nav';
import { BrowserRouter as Router, Route, Routes } from "react-router-dom";
import Home from "./components/home/Home";
import About from "./components/about/About";
import Services from "./components/services/Services";
import Portfolio from "./components/portfolio/Portfolio";
import Prices from "./components/prices/Prices";
import Questions from "./components/questions/Questions";
import Contacts from "./components/contacts/Contacts";
import Footer from './components/footer/footer';
import PrintWall from "./components/sliderLink/PrintWall";
import PrintOnWall from './components/services/service.printonwall';
import PrintOutsideWall from './components/services/service.printoutsidewall';
import PrintMirror from './components/services/service.printmirror';
import AutoPrint from './components/services/service.autoprint';
import Rooms from './components/portfolio/Rooms';
import Schools from './components/portfolio/Schools';
import Offices from './components/portfolio/Offices';
import Houses from './components/portfolio/Houses';
import KidRooms from './components/portfolio/Kidrooms';
import './fonts/fonts.css';
import CafeBars from './components/portfolio/CafeBars';
import Autos from './components/portfolio/Auto';

function App() {
  const [language, setLanguage] = useState(() => {
     // Retrieve language from local storage
     const storedLanguage = localStorage.getItem('language');
     // If language is stored in local storage, use it, otherwise default to 'GEO'
     return storedLanguage ? storedLanguage : 'GEO';
  });

  useEffect(() => {
    // Save language to local storage whenever it changes
    localStorage.setItem('language', language);
  }, [language]);
  // const [language, setLanguage] = useState('geo')
  return (
    
    <Router >  
      <Navbar onLanguageChange={setLanguage} language={language} />
      <Routes>
        <Route path="/" element={<Home language={language}/>} />
        <Route path="/about" element={<About language={language}/>} />
        <Route path="/Services" element={<Services language={language}/>} />
        <Route path="/Order" element={<Portfolio language={language}/>} />
        <Route path="/Prices" element={<Prices language={language}/>} />
        <Route path="/Questions" element={<Questions language={language}/>} />
        <Route path="/Contacts" element={<Contacts language={language}/>} />
        <Route path="/PrintWall" element={<PrintWall language={language}/>} />
        <Route path="/Service/PrintOnWall" element={<PrintOnWall language={language}/>} />
        <Route path="/Service/PrintOutsideWall" element={<PrintOutsideWall language={language}/>} />
        <Route path="/Service/PrintMirror" element={<PrintMirror language={language}/>} />
        <Route path="/Service/AutoPrint" element={<AutoPrint language={language}/>} />
        <Route path="/Order/Rooms" element={<Rooms language={language}/>} />
        <Route path="/Order/Schools" element={<Schools language={language}/>} />
        <Route path="/Order/Offices" element={<Offices language={language}/>} />
        <Route path="/Order/Houses" element={<Houses language={language}/>} />
        <Route path="/Order/KidRooms" element={<KidRooms language={language}/>} />
        <Route path="/Order/CafeAndBars" element={<CafeBars language={language}/>} />
        <Route path="/Order/Autos" element={<Autos language={language}/>} />
      </Routes>
        <Footer language={language}/>
    </Router>
  );
}

export default App;
