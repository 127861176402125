
export const AboutData = [
    {
        header: 'კომპანიის შესახებ',
        lang:'GEO',
        text: 'PrintWall.ge გთავაზობთ მაღალი ხარისხის მომსახურეობას ინტერიერის და ექსტერიერის დიზაინის შექმნაში. უნიკალური ინტერიერის შესაქმნელად, კედლის პრინტერის გამოყენებით შევასრულებთ თქვენთვის სასურველ ნებისმიერ ნახატს, ნებისმიერ ზედაპირზე.  შეგიძლიათ აირჩიოთ ნებისმიერი სურათი და დარწმუნებული იყოთ, რომ ის კედელზე ისეთივე ხარისხის იქნება, როგორც ეკრანზე. ჩვენი კომპანიის გამოცდილი თანამშრომლები ინდივიდუალური მიდგომით განიხილავენ თითოეულ შეკვეთას და ასრულებენ სამუშაოს მოკლე დროში. Printwall-თან ერთად კედლებზე UV ბეჭდვა საქართველოში   უფრო ხელმისაწვდომი და მომგებიანი გახდა.',

    },
    {
        header: 'About company',
        lang:'ENG',
        text: `PrintWall.ge offers high-quality services in the creation of interior and exterior design. To create a unique interior, we will use a wall printer to make any picture you want on any surface. You can choose any image and be sure that it will be of the same quality on the wall as it is on the screen. Experienced employees of our company consider each order with an individual approach and complete the work in a short time. With Printwall, UV printing on walls has become more affordable and profitable in Georgia.`,

    },
    {
        header: 'О компании',
        lang:'RUS',
        text: ` PrintWall.ge предлагает качественные услуги по созданию дизайна интерьера и экстерьера. Для создания уникального интерьера мы воспользуемся настенным принтером, чтобы нанести на любую поверхность любую картинку по вашему желанию. Вы можете выбрать любое изображение и быть уверенным, что на стене оно будет такого же качества, как и на экране. Опытные сотрудники нашей компании рассматривают каждый заказ с индивидуальным подходом и выполняют работу в короткие сроки. Благодаря Printwall UV-печать на стенах стала в Грузии более доступной и прибыльной.`,

    },
    {
         subheader: 'კომპანიის მისია',
         lang:'GEO',
        text: `ჩვენი მისიაა კედელზე ინოვაციური და პერსონალიზებული 3D UV ბეჭდვის შესაძლებლობით გავამრავალფეროვნოთ ხალხის აღქმა ინტერიერისა და ექსტერიერის დიზაინზე  . ჩვენ გთავაზობთ კრეატიულობას, ყველა ზედაპირთან თავსებადობას და ხარისხს, რათა თქვენი სივრცეები , გარდაიქმნას ინდივიდუალურობის ძლიერ გამოხატულებად.`
    },
    {
         subheader: 'Миссия компании',
         lang:'RUS',
        text: `Наша миссия — улучшить восприятие людьми дизайна интерьера и экстерьера с помощью инновационных и персонализированных возможностей 3D-УФ-печати стен. Мы предлагаем креативность, совместимость со всеми поверхностями и качество, чтобы превратить ваши помещения в мощное выражение индивидуальности.`
    },
    {
         subheader: 'Company mission',
         lang:'ENG',
        text: `Our mission is to enhance people's perception of interior and exterior design with innovative and personalized 3D UV wall printing capabilities. We offer creativity, compatibility with all surfaces and quality to transform your spaces into powerful expressions of individuality.`
    },
    {
        subheader: 'კომპანიის ხედვა',
        lang:'GEO',

        text: `კომპანია ორიენტირებულია   გლობალურად აღიარებული , უახლესი ტექნოლოგიების დახმარებით, ბეჭდვის განსაკუთრებული ოსტატობით შესრულებასა და მომხმარებელთა კმაყოფილებაზე. ჩვენ ვცდილობთ წავახალისოთ კრეატიულობა და კედლების უნიკალური, ვიზუალურად მრავალფეროვანი დიზაინით დავეხმაროთ ინდივიდებსა და ბიზნესებს თავიანთი გარემოს განახლებაში .`
    },     
    {
        subheader: 'Company vision',
        lang:'ENG',

        text: `The company is focused on providing exceptional print performance and customer satisfaction with the help of recognized and latest technologies. We strive to encourage creativity and help individuals and businesses renew their environment with unique, visually diverse wall designs.`
    },     
    {
        subheader: 'Видение компании',
        lang:'RUS',

        text: `Компания ориентирована на обеспечение исключительной производительности печати и удовлетворенности клиентов с помощью признанных и новейших технологий. Мы стремимся поощрять творчество и помогать частным лицам и предприятиям обновлять свою среду с помощью уникального, визуально разнообразного дизайна стен.`
    },     
    {
        subheader: 'კომპანიის მიზნები',
        lang:'GEO',
        textArray: [
            {
                subtext: 'ინოვაცია: ',
                lang:'GEO',

            maintext: `განუწყვეტლივ შევისწავლოთ და განვავითაროთ ახალი ტექნიკა, მასალები და ტექნოლოგიები, რათა დავრჩეთ ინდუსტრიის ტენდენციების მოწინავე ადგილზე.`
            },
            {
                subtext: 'ხარისხი: ',
                lang:'GEO',

               maintext: `ევინარჩუნოთ ხარისხის კონტროლის მკაცრი სტანდარტები, რათა დარწმუნებული ვიყოთ, რომ თითოეული ნაბეჭდი, გამძლეობის, სიზუსტისა და ესთეტიკური მიმზიდველობის თვალსაზრისით, აკმაყოფილებს და აღემატება მომხმარებელთა მოლოდინს. `
            },
            {
                subtext: 'მომხმარებელთა კმაყოფილება: ',
                lang:'GEO',

                maintext: `ჩვენთვის პრიორიტეტულია მომხმარებელთა საჭიროებები და მოთხოვნები. ჩვენ ვუზრუნველვყოფთ პერსონალურ კონსულტაციებს, სწრაფ კომუნიკაციასა და განსაკუთრებულ მომსახურეობას საკითხით პირველადი დაინტერესებიდან პროექტის დასრულებამდე.`
            },
            {
                subtext: 'მდგრადობა: ',
                lang:'GEO',

                maintext: `დავნერგოთ ეკოლოგიურად სუფთა პრაქტიკა, რაც მოიცავს არატოქსიკური საღებავების გამოყენებას, ნარჩენების მინიმუმამდე შემცირებასა და მასალების პასუხისმგებლობით მოპოვებას.`
            },
            {
                subtext: 'ბაზრის გაფართოება: ',
                lang:'GEO',

                maintext: `გავაფართოვოთ ჩვენი ხელმისაწვდომობა ახალ გეოგრაფიულ არეალებზე. მონაწილეობა მივიღოთ აღნიშნულ ბაზარზე არსებულ მიზნობრივ მარკეტინგულ კამპანიებსა და ინდუსტრიის ღონისძიებებში.`
            },
            {
                subtext: 'განათლება და ინფორმირებულობა: ',
                lang:'GEO',

                maintext: `სემინარების, დემონსტრაციებისა და ონლაინ რესურსების მეშვეობით, ავამაღლოთ მომხმარებლების, დიზაინერებისა და არქიტექტორების ცნობიერება 3D UV ბეჭდვის შესაძლებლობების შესახებ, რაც ხელს შეუწყობს ჩვენს სერვისებზე მოთხოვნის გაზრდას.`
            },
            {
                subtext: 'თანამშრომლობა: ',
                lang:'GEO',

                maintext: `განვავითაროთ პარტნიორობა ინტერიერის დიზაინერებთან, არქიტექტორებთან, დეველოპერებთან და სხვა დაინტერესებულ მხარეებთან, რათა ვითანამშრომლოთ ინოვაციურ პროექტებზე და გავაძლიეროთ ზედაპირებზე 3D UV ბეჭდვის ინტეგრაცია სამშენებლო და ინტერიერის დიზაინში.`
            },
            {
                subtext: 'თანამშრომლების განვითარება: ',
                lang:'GEO',

                maintext: ` ჩავდოთ ინვესტიცია ჩვენი გუნდის წევრების ტრენინგებსა და პროფესიულ განვითარებაში.`
            },
            {
                subtext: 'ბრენდის აღიარება: ',
                lang:'GEO',

                maintext: `მიზნობრივი მარკეტინგული საშუალებებით, სოციალური მედიის ჩართულობით, გამოფენებში მონაწილეობითა და მომხმარებელთა დადებითი გამოხმაურების მეშვეობით გავზარდოთ ბრენდის ცნობადობა და სანდოობა.
                `
            },
            {
                subtext: 'საზოგადოების ჩართულობა: ',
                lang:'GEO',

                maintext: `სოციალური პასუხისმგებლობის ფარგლებში, მონაწილეობა მივიღოთ ადგილობრივ საქველმოქმედო ღონისძიებებში ჩვენი სერვისების შევთავაზოთ საზოგადოებისთვის მნიშვნელოვან პროექტებს.`
            }
            
        ]
    },
    {
        subheader: 'Company goals',
        lang:'ENG',
        textArray: [
            {
                subtext: 'Innovation: ',

            maintext: `Continuously research and develop new techniques, materials and technologies to stay ahead of industry trends.`
            },
            {
                subtext: 'Quality: ',
                lang:'GEO',

               maintext: `We maintain strict quality control standards to ensure that each print meets and exceeds customer expectations in terms of durability, accuracy and aesthetic appeal. `
            },
            {
                subtext: 'Customer satisfaction: ',
                lang:'GEO',

                maintext: `Customer needs and requirements are our priority. We provide personal consultation, prompt communication and exceptional service from initial inquiry to project completion.`
            },
            {
                subtext: 'Sustainability: ',
                lang:'GEO',

                maintext: `Implement environmentally friendly practices that include using non-toxic paints, minimizing waste, and responsibly sourcing materials.`
            },
            {
                subtext: 'Market Expansion: ',
                lang:'GEO',

                maintext: `Expand our reach to new geographic areas. Participate in targeted marketing campaigns and industry events in said market.`
            },
            {
                subtext: 'Education and Awareness: ',
                lang:'GEO',

                maintext: `Through workshops and online resources, raise the awareness of consumers, designers and architects about the possibilities of 3D UV printing, which will help to increase the demand for our services.`
            },
            {
                subtext: 'Collaboration: ',
                lang:'GEO',

                maintext: `Develop partnerships with interior designers, architects, developers and other stakeholders to collaborate on innovative projects and enhance the integration of 3D UV surface printing in construction and interior design.`
            },
            {
                subtext: 'Employee Development: ',
                lang:'GEO',

                maintext: `Invest in training and professional development of our team members`
            },
            {
                subtext: 'Brand Recognition: ',
                lang:'GEO',

                maintext: `Increase brand awareness and credibility through targeted marketing, social media engagement, trade show participation and positive customer feedback.
                `
            },
            {
                subtext: 'Community involvement: ',
                lang:'GEO',

                maintext: `within the framework of social responsibility, participate in local charity events and offer our services to projects important to society.`
            }
            
        ]
    },
    {
        subheader: 'Цели компании',
        lang:'RUS',
        textArray: [
            {
                subtext: 'Инновации: ',
                lang:'GEO',

            maintext: `Постоянно исследуйте и разрабатывайте новые методы, материалы и технологии, чтобы опережать тенденции отрасли.`
            },
            {
                subtext: 'Качество: ',
                lang:'GEO',

               maintext: `Мы поддерживаем строгие стандарты контроля качества, чтобы гарантировать, что каждый отпечаток соответствует ожиданиям клиентов и превосходит их с точки зрения долговечности, точности и эстетической привлекательности.`
            },
            {
                subtext: 'Удовлетворенность клиентов: ',
                lang:'GEO',

                maintext: `Потребности и требования клиентов являются нашим приоритетом. Мы предоставляем персональные консультации, оперативное общение и исключительный сервис от первоначального запроса до завершения проекта.`
            },
            {
                subtext: 'Устойчивость: ',
                lang:'GEO',

                maintext: `Внедряйте экологически безопасные методы, включающие использование нетоксичных красок, минимизацию отходов и ответственный подход к выбору материалов.`
            },
            {
                subtext: 'Расширение рынка: ',
                lang:'GEO',

                maintext: `Расширьте нашу деятельность на новые географические регионы. Участвовать в целевых маркетинговых кампаниях и отраслевых мероприятиях на указанном рынке.`
            },
            {
                subtext: 'Образование и повышение осведомленности: ',
                lang:'GEO',

                maintext: `С помощью семинаров и онлайн-ресурсов повышайте осведомленность потребителей, дизайнеров и архитекторов о возможностях 3D-UV-печати, что поможет повысить спрос на наши услуги.`
            },
            {
                subtext: 'Сотрудничество: ',
                lang:'GEO',

                maintext: `развивать партнерские отношения с дизайнерами интерьеров, архитекторами, разработчиками и другими заинтересованными сторонами для совместной работы над инновационными проектами и расширения интеграции 3D-UV-печати на поверхности в строительство и дизайн интерьера.`
            },
            {
                subtext: 'Развитие сотрудников: ',
                lang:'GEO',

                maintext: `инвестируйте в обучение и профессиональное развитие членов нашей команды.`
            },
            {
                subtext: 'Узнаваемость бренда: ',
                lang:'GEO',

                maintext: `Повышайте узнаваемость бренда и доверие к нему посредством целевого маркетинга, взаимодействия с социальными сетями, участия в выставках и положительных отзывов клиентов.
                `
            },
            {
                subtext: 'Участие сообщества: ',
                lang:'GEO',

                maintext: `в рамках социальной ответственности участвуйте в местных благотворительных мероприятиях и предлагайте свои услуги важным для общества проектам.`
            }
            
        ]
    },
]