import React from "react";
import VideoToPlay from './../../video/video.mp4'
import './home.css'
import { useMediaQuery } from "react-responsive";

const VideoShow = () => {
    // const [showVideo, setShowVideo] = useState(false);
    const smallScreen = useMediaQuery({query: '(max-width: 700px)'})
    return (
        <div className={`${smallScreen ? 'px-10 py-8' : 'px-24 py-14'} flex justify-center`} >
                <div className="video-container">
                    <video controls >
                        <source src={VideoToPlay} type="video/mp4" />
                    </video>
                </div>
        </div>
    );
}

export default VideoShow;