import React from "react";
import { ServiceData } from "./services.data"; 
import './../../fonts/fonts.css';
import './service.css';
import { useMediaQuery } from "react-responsive";
import { Helmet } from "react-helmet";
import keywordsData from './../keywords.json'


const PrintMirror = ({ language }) => {
    const bigScreen = useMediaQuery({query: '(max-width: 1460px)'})
    const bigMediumScreen = useMediaQuery({query: '(max-width: 1420px)'})
    const bigMediumScreenSecond = useMediaQuery({ query: '(max-width: 1300px)' })
    const filteredData = ServiceData.filter(item => item.lang === language);  
    const firstService = filteredData[2];
    const keywords = keywordsData.keywords;

    return (
        <div>
            <Helmet>
                <title>Printwall - {language === 'GEO' ? 'სერვისები' : language === 'ENG' ? 'Services' : 'Услуги'}</title>
                <meta name="description" content="Explore Printwall's comprehensive range of services offering automated drawing solutions for transforming your walls. From custom designs to rapid execution, our services deliver innovative wall art with precision and affordability." />
                <meta name="description" content="Изучите широкий спектр услуг Printwall, предлагающих автоматизированные решения для рисования, которые преобразят ваши стены. От индивидуального дизайна до быстрого выполнения — наши услуги обеспечивают инновационную настенную живопись с точностью и доступностью." />
                <meta name="description" content="გამოიკვლიეთ Printwall-ის სერვისების ყოვლისმომცველი ასორტიმენტი, რომელიც გთავაზობთ ავტომატური ნახაზის გადაწყვეტილებებს თქვენი კედლების გარდაქმნისთვის. მორგებული დიზაინიდან დაწყებული სწრაფ შესრულებამდე, ჩვენი სერვისები აწვდიან ინოვაციურ კედლის ხელოვნებას სიზუსტით და ხელმისაწვდომ ფასად." />
                {keywords.map(keyword => (
                    <meta name="keywords" content={keyword} key={keyword} />
                ))}
            </Helmet>
            <div  key={firstService.header}>
                <div className={`${bigScreen ? (bigMediumScreen ? (bigMediumScreenSecond ? 'px-10' :'px-16') : 'px-20') : 'px-24' } py-16 services`}>
                    <h2>{firstService.header}</h2>
                    <div className="grid justify-between grid-cols-2 gap-x-4" style={{  alignItems: 'stretch' }}>
                                <p style={bigMediumScreenSecond ? {} : { whiteSpace: 'pre-line',textAlign:'justify'  }}>{firstService.text}</p>
                                <img src={firstService.image} alt={ firstService.header}  style={{ width: "100%", height: "100%", objectFit: "cover", objectPosition: "center"}}/>    
                            </div>
                </div>
                <div className="info-content-service"></div>
            </div>
        </div>
    )
}

export default PrintMirror;