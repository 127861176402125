// Home.js
import React from "react";
import { QuestionsData } from "./questions.data";
import './questions.css';
import { useMediaQuery } from "react-responsive";
import { Helmet } from "react-helmet";
import keywordsData from './../keywords.json'
const Questions = ({ language }) => {
    const filteredData = QuestionsData.filter(item => item.lang === language);  
    const keywords = keywordsData.keywords;

    const bigScreen = useMediaQuery({query: '(max-width:  1330px)'})
    const mediumScreen = useMediaQuery({query: '(max-width:  1200px)'})

    return (
        <div className={`flex flex-col gap-3 ${bigScreen ? 'px-10' : 'px-24'} gap-y-14 py-16 questions`} >
             <Helmet>
                <title>Printwall - {language === 'GEO'?'კითხვა-პასუხი' :language === 'ENG'?'FAQs':'Вопросы и ответы' }</title>
                <meta name="description" content="Find answers to common queries about Printwall's automated drawing solutions in our comprehensive FAQs section. Learn about our services, pricing, customization options, and more to make informed decisions for transforming your walls." />
                <meta name="description" content="იპოვეთ პასუხები გავრცელებულ შეკითხვებზე Printwall-ის ავტომატური ნახაზის გადაწყვეტილებების შესახებ ჩვენს ყოვლისმომცველ ხშირად დასმული კითხვების განყოფილებაში. შეიტყვეთ ჩვენი სერვისების, ფასების, პერსონალიზაციის ვარიანტებისა და მეტის შესახებ, რათა მიიღოთ ინფორმირებული გადაწყვეტილებები თქვენი კედლების ტრანსფორმაციისთვის." />
                <meta name="description" content="Найдите ответы на распространенные вопросы о решениях для автоматического рисования Printwall в нашем обширном разделе часто задаваемых вопросов. Узнайте о наших услугах, ценах, возможностях индивидуальной настройки и многом другом, чтобы принять обоснованные решения по преобразованию ваших стен." />
                {keywords.map(keyword => (
                    <meta name="keywords" content={keyword} key={keyword} />
                ))}
            </Helmet>
            <h2>{language === 'GEO' ? 'კითხვა-პასუხი' : language === 'eng' ? 'Questions and answer' : 'Вопрос и ответ'}</h2>
            <div className="flex flex-col gap-10 questions-container">
                {filteredData.map((questions,index) => {
                    return (
                        <div key={index}>
                            <h3>{questions.subheader}</h3>
                            <p style={!mediumScreen ? { whiteSpace: 'pre-line'} : {}}>{ questions.text }</p>
                        </div>
                    )
                })}
            </div>
        </div>
    );
};

export default Questions;