import watch from "./../../images/stopwatch1.png"
import eco from "./../../images/ekologiurad-supta1.png"
import certficate from "./../../images/certificate1.png"

export const SmallInfoData = [
    {
        image: `${watch}`,
        lang:'GEO',
        infoHeader: "სისწრაფე",
        infoText: `საათში 5-15 მ²-მდე,  
        არ საჭიროებს გაშრობას`
    },
    {
        image: `${watch}`,
        lang:'ENG',
        infoHeader: "speed",
        infoText: `up to 5-15 m² per hour,
        Does not require drying`
    },
    {
        image: `${watch}`,
        lang:'RUS',
        infoHeader: "скорость",
        infoText: `до 5-15 м² в час,
        Не требует сушки`
    },
    {
        image: `${certficate}`,
        lang:'GEO',
        infoHeader: "ხარისხი",
        infoText: `უახლესი ტექნოლოგიების 
        დახმარებით,გამოსახულება
        კედელზე იქნება ისეთივე
        ხარისხის როგორც ეკრანზე.
        `
    },
    {
        image: `${certficate}`,
        lang:'ENG',
        infoHeader: "Quality",
        infoText: `With the help of the latest 
        technologies, the image on the wall will
        be of the same quality as on the screen.
        `
    },
    {
        image: `${certficate}`,
        lang:'RUS',
        infoHeader: "Качество",
        infoText: `Благодаря новейшим технологиям 
        изображение на стене будет такого же
        качества, как и на экране.
        `
    },
    {
        image: `${eco}`,
        lang:'GEO',
        infoHeader: "უსაფრთხოება ",
        infoText: `ვიყენებთ მხოლოდ 
        შესაბამისი სერთიფიკატის მქონე 
        პრემიუმ ხარისხის საღებავებს.`
    },
    {
        image: `${eco}`,
        lang:'ENG',
        infoHeader: "Safety ",
        infoText: `We use only premium 
        quality paints with appropriate 
        certificates.`
    },
    {
        image: `${eco}`,
        lang:'RUS',
        infoHeader: "Безопасность ",
        infoText: `Мы используем только 
        краски премиум-класса, имеющие 
        соответствующие сертификаты.`
    }
]