import React, { useState, useEffect } from "react";

const LanguageDropdown = ({ language, onLanguageChange }) => {
  const [isOpen, setIsOpen] = useState(false);

  const toggleDropdown = () => {
    setIsOpen(!isOpen);
  };

  const handleLanguageChange = (lang) => {
    onLanguageChange(lang);
    setIsOpen(false); // Close the dropdown after selecting a language
  };

  return (
    <div className="language-dropdown">
      <button className="dropdown-toggle" onClick={toggleDropdown}>
        {language}
      </button>
      {isOpen && (
        <ul className="z-50 dropdown-menu">
          <li>
            <button onClick={() => handleLanguageChange('GEO')} className={language === 'GEO' ? 'active' : ''}>GEO</button>
          </li>
          <li>
            <button onClick={() => handleLanguageChange('ENG')} className={language === 'ENG' ? 'active' : ''}>ENG</button>
          </li>
          <li>
            <button onClick={() => handleLanguageChange('RUS')} className={language === 'RUS' ? 'active' : ''}>RUS</button>
          </li>
        </ul>
      )}
    </div>
  );
};

export default LanguageDropdown;