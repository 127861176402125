export const PricesData = [
    {
        texts: [
            {
                lang: 'ENG',
                text: `
                Digital wall art is a modern solution for any interior that allows you to express your originality.
                This design method is used in the renovation of apartments, public spaces, shopping centers, organizations
                and educational institutions. For promotions and discounts, follow our Facebook and Instagram pages.
`
            },
            {
                lang: 'GEO',
                text:`კედლის ციფრული მხატვრობა არის თანამედროვე გადაწყვეტა ნებისმიერი ინტერიერისთვის, რომლის საშუალებითაც 
                შეგიძლიათ გამოხატოთ თქვენი ორიგინალურობა. დიზაინის ეს მეთოდი გამოიყენება ბინების, საჯარო სივრცეების, სავაჭრო 
                ცენტრების, ორგანიზაციებისა და საგანმანათლებლო დაწესებულებების რემონტში.
                აქციებისა და ფასდაკლებების სანახავად, თვალი ადევნეთ ჩვენ Facebook და Instagram გვერდებს.
                `
            },
            {
                lang: 'RUS',
                text:`Цифровое настенное искусство – современное решение для любого интерьера, позволяющее выразить свою 
                оригинальность. Этот метод проектирования используется при ремонте квартир, общественных помещений, торговых 
                центров, организаций и учебных заведений.
                За акциями и скидками следите на наших страницах в Facebook и Instagram.
                `
            }
        ]
    },
    {
        textArray: [
        {
            lang:'GEO',
            m2: '1-5 ',
            prices: '144.5'
        },
        {
            lang:'ENG',
            m2: '1-5 ',
            prices: '144.5'
        },
        {
            lang:'RUS',
            m2: '1-5 ',
            prices: '144.5'
        },
        {
            lang:'GEO',
            m2: '6-7 ',
            prices: '127.5'
        },
        {
            lang:'ENG',
            m2: '6-7 ',
            prices: '127.5'
        },
        {
            lang:'RUS',
            m2: '6-7 ',
            prices: '127.5'
        },
        {
            lang:'GEO',
            m2: '8-10 ',
            prices: '115.5'
        },
        {
            lang:'ENG',
            m2: '8-10 ',
            prices: '115.5'
        },
        {
            lang:'RUS',
            m2: '8-10 ',
            prices: '115.5'
        },
        {
            lang:'GEO',
            m2: '11-49 ',
            prices: '100'
        },
        {
            lang:'ENG',
            m2: '11-49 ',
            prices: '100'
        },
        {
            lang:'RUS',
            m2: '11-49 ',
            prices: '100'
        },
        {
            lang:'GEO',
            m2: '50-99 ',
            prices: '85.5'
        },
        {
            lang:'ENG',
            m2: '50-99 ',
            prices: '85.5'
        },
        {
            lang:'RUS',
            m2: '50-99 ',
            prices: '85.5'
        },
        {
            lang:'GEO',
            m2: '100 ',
            prices: '71.5'
        },
        {
            lang:'ENG',
            m2: '100 ',
            prices: '71.5'
        },
        {
            lang:'RUS',
            m2: '100 ',
            prices: '71.5'
        },
        ]
    }
]