import React from 'react';
import { PrintWallData } from './printwall.data';
import './printWall.css';
import { useMediaQuery } from 'react-responsive';
import { Helmet } from 'react-helmet';
import keywordsData from './../keywords.json'
const PrintWall = ({ language }) => {
    const bigScreen = useMediaQuery({ query: '(max-width: 1460px)' })
    const bigMiddleScreen = useMediaQuery({ query: '(max-width:1370px)' })
    const filteredData = PrintWallData.filter(item => item.lang === language);
    const keywords = keywordsData.keywords;
    return (
        <div className='py-16 print-wall-content'>
            <Helmet>
                <title>Printwall - {language === 'GEO'?'ხატვა თბილისსა და რეგიონებში' :language === 'ENG'?'Painting in Tbilisi and regions':'Покраска в Тбилиси и регионах' }</title>
                <meta name="description" content="Discover professional painting services in Tbilisi and surrounding regions. We offer expert painting solutions for residential and commercial properties. Contact us today for a free quote!" />
                <meta name="description" content="აღმოაჩინეთ პროფესიონალური ფერწერის სერვისები თბილისსა და მიმდებარე რეგიონებში. ჩვენ გთავაზობთ საექსპერტო ფერწერის გადაწყვეტილებებს საცხოვრებელი და კომერციული ფართებისთვის. დაგვიკავშირდით დღეს უფასო შეთავაზებისთვის!" />
                <meta name="description" content="Откройте для себя профессиональные малярные услуги в Тбилиси и прилегающих регионах. Мы предлагаем профессиональные решения по покраске жилых и коммерческих объектов. Свяжитесь с нами сегодня для бесплатного предложения!" />
                {keywords.map(keyword => (
                    <meta name="keywords" content={keyword} key={keyword} />
                ))}
            </Helmet>
            {filteredData.map(PrintWallDataItems => (
                
                
                    <div className='flex flex-col gap-14 content '>
                        <h2 className='w-fit'>{PrintWallDataItems.header}</h2>
                        <p style={!bigMiddleScreen ? { whiteSpace: 'pre-line',textAlign:'justify'  } : {textAlign:'justify' }} className={`${bigScreen ? 'w-full' : 'w-fit '}`}>{PrintWallDataItems.content}</p>
                        <div className='mt-4 ' >
                            <img src={PrintWallDataItems.image} alt={PrintWallDataItems.header } />
                        </div>
                    </div>
                
            ))}
        </div>
    )
}
export default PrintWall;