import React from "react";
import { SmallInfoData } from "./small.info.data";
import "./home.css";
import { useMediaQuery } from "react-responsive";

const SmallInfo = ({language}) => { 

    const tooBigTooFit = useMediaQuery({query: '(max-width:1370px)'});
    const tooBigTooBeSmall = useMediaQuery({query: '(max-width:1200px)'});
    const tooSmallTooBeBig = useMediaQuery({ query: '(min-width:1100px)' });
    const filteredData = SmallInfoData.filter(item => item.lang === language);  

    return (
        <div className={`flex  ${tooSmallTooBeBig ?'flex-row' : ' flex-col gap-10'} items-center justify-between ${tooBigTooFit ? (tooBigTooBeSmall ? 'px-10' :  'px-20') : 'px-40' }  pt-12 pb-16 info-content`}>
            {filteredData.map((info, index) => {
                
                return (
                    <div className={`flex flex-col gap-y-6 small-info-div`} key={index}>
                        <img src={info.image} alt={index} className="self-center images" />
                        <div className="flex flex-col items-center self-start gap-y-4">
                            <h2 className="text-2xl text-white">{info.infoHeader}</h2>
                            <p style={tooSmallTooBeBig ? { whiteSpace: 'pre-line' } : {}} className="text-xl text-center text-white">{info.infoText}</p>
                        </div>
                    </div>
                )   
            })}
        </div>
    )
}

export default SmallInfo;