import React from "react";
import './footer.css'
import EmailButton from "./email.footer";
import Phone from './../../images/smartphone.png'
import { useMediaQuery } from "react-responsive";
import {aboutFooter} from './about.footer.data'

const AboutFooter = ({ language }) => {
    const filteredData = aboutFooter.filter(item => item.lang === language);  

    const mobileSizesScreen = useMediaQuery({query: '(max-width: 374px)'})
    return (
        <div className="flex flex-col gap-y-2 w-fit about-font">
            <h2 className="text-2xl font-bold text-white">PRINTWALL</h2>
            {
                filteredData.map((texts, index) => {
                    return (
    
                    <h2 className="text-xs text-white w-96" style={{whiteSpace:'pre-line',textAlign: 'justify' }}>
                    {texts.text}
                    </h2>
)
                })
            }
                    
                       
            
            <div className={`flex ${mobileSizesScreen?'flex-col':'flex-row'} pt-4 gap-x-6`}>
                <div className='flex flex-row items-center gap-x-3 w-fit '>
                    <img src={Phone} alt="Email Icon" className='w-4 h-fit'/>
                    <p className='text-white text-m'>550 00 22 05</p>
                </div>
                <EmailButton email="info@printwall.ge"/>
            </div>
            {
                filteredData.map((texts, index) => {
                    return (
                        <h1 className="text-xs text-white" style={{ }}>
                            {texts.address}
                        </h1>
                        )           
                })
            }
        </div>
    )

}

export default AboutFooter;