import React from "react";
import "./../home.css"
import FormDetails from "./fromDetails";
import { useMediaQuery } from "react-responsive";
import FormImage from "./../../../images/Mask group (4).png"

const Form = ({language}) => {
    const isBigScreen = useMediaQuery({ query: '(min-width: 1450px)' })
    const isMediumScreen = useMediaQuery({ query: '(min-width:1120px)' })

    return (
        
        <div className={` ${!isMediumScreen ? 'flex flex-col pb-2':'flex-row grid grid-cols-2'}`}>
            <img src={FormImage} alt="FormImage" className={`${isBigScreen ? '' : 'h-full'} ${isMediumScreen ? ' ' : ' h-fit'}`} />
            <div className="flex flex-col justify-center">

            <FormDetails language={ language} />
            </div>
        </div>
    )
}
export default Form;